
import React from 'react';
import { Col, Pagination, Row } from 'react-bootstrap';

const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
 const handlePageChange = (newPage) => {
    onPageChange(newPage);
 };

 const renderPageNumbers = () => {
    const pages = [];
    // Calculate the start and end of the range
    let startPage = Math.max(1, currentPage - 1);
    let endPage = Math.min(totalPages, currentPage + 1);

    // Adjust the range if it's too close to the start or end
    if (endPage - startPage < 3) {
      if (startPage === 1) {
        endPage = Math.min(3, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 3, 1);
      }
    }

    for (let i = startPage; i <= endPage; i++) {
      pages.push(
        <Pagination.Item
          key={i}
          active={i === currentPage}
          onClick={() => handlePageChange(i)}
          style={{ backgroundColor: i === currentPage ? '#000F3F' : 'transparent', color: i === currentPage ? '#FFFFFF' : '#000F3F' }}
        >
          {i}
        </Pagination.Item>
      );
    }
    return pages;
 };

 return (
  <div style={{width:'100%'}}>
    <Pagination>
      <Row style={{width:'100%'}}>
        <Col md={12} lg={9} xl={9}>
        <div style={{display:'flex',flexDirection:'row'}}>
        <Pagination.First
        onClick={() => handlePageChange(1)}
        disabled={currentPage === 1}
        style={{ color: '#FFFFFF' }}
      />
      <Pagination.Prev
        onClick={() => handlePageChange(currentPage - 1)}
        disabled={currentPage === 1}
        style={{ color: '#FFFFFF' }}
      />
      {renderPageNumbers()}
      <Pagination.Next
        onClick={() => handlePageChange(currentPage + 1)}
        disabled={currentPage === totalPages}
        style={{ color: '#FFFFFF' }}
      />
      <Pagination.Last
        onClick={() => handlePageChange(totalPages)}
        disabled={currentPage === totalPages}
        style={{ color: '#FFFFFF' }}
      />
        </div>
        </Col>
        <Col md={12} lg={3} xl={3} className="pagination-total" style={{fontWeight:'bold',}}>
        <span >
        Total Pages: {totalPages}
      </span>
        </Col>
      </Row>
      
      
    </Pagination>
    </div>
 );
};

export default CustomPagination;




// import React from 'react';
// import { Pagination } from 'react-bootstrap';

// const CustomPagination = ({ currentPage, totalPages, onPageChange }) => {
//   const handlePageChange = (newPage) => {
//     onPageChange(newPage);
//   };

//   const renderPageNumbers = () => {
//     const pages = [];
//     for (let i = 1; i <= Math.min(totalPages, 5); i++) {
//       pages.push(
//         <Pagination.Item
//           key={i}
//           active={i === currentPage}
//           onClick={() => handlePageChange(i)}
//           style={{ backgroundColor: i === currentPage ? '#000F3F' : 'transparent', color: i === currentPage ? '#FFFFFF' : '#000F3F' }}
//         >
//           {i}
//         </Pagination.Item>
//       );
//     }
//     return pages;
//   };

//   return (
//     <Pagination>
//       <Pagination.First
//         onClick={() => handlePageChange(1)}
//         disabled={currentPage === 1}
//         style={{ color: '#FFFFFF' }}
//       />
//       <Pagination.Prev
//         onClick={() => handlePageChange(currentPage - 1)}
//         disabled={currentPage === 1}
//         style={{ color: '#FFFFFF' }}
//       />
//       {renderPageNumbers()}
//       <Pagination.Next
//         onClick={() => handlePageChange(currentPage + 1)}
//         disabled={currentPage === totalPages}
//         style={{ color: '#FFFFFF' }}
//       />
//       <Pagination.Last
//         onClick={() => handlePageChange(totalPages)}
//         disabled={currentPage === totalPages}
//         style={{ color: '#FFFFFF' }}
//       />
//       <span className="pagination-total" style={{fontWeight:'bold',marginLeft:'3px'}}>
//         Total Pages: {totalPages}
//       </span>
//     </Pagination>
//   );
// };

// export default CustomPagination;
