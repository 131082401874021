import React, { useState } from 'react';
import Drawer from '../menu/index';
import '../assets/Layout.css';
import Header from '../header/header';
import { Outlet } from 'react-router-dom';

const NonAuth = ({ children }) => {
  const [isDrawerOpen, setIsDrawerOpen] = useState(true);
  const handleDrawerToggle = () => {
    setIsDrawerOpen(!isDrawerOpen);
  };
  return (
    <div >
      <div className={isDrawerOpen === true ? 'headertoggle' : 'headert'} >
        <Header onclik={handleDrawerToggle} drawer={isDrawerOpen} />
      </div>
      <Drawer isOpen={isDrawerOpen} />
      <div className={isDrawerOpen === true ? 'content-wrapper' : 'cdf'}>
        <main className='main'>
           <Outlet/>
        </main>
      </div>
    </div>
  );
};

export default NonAuth;