import { all } from 'redux-saga/effects';
import authLoginWatcher from './authSaga';


function* rootSaga() {
  yield all([
    authLoginWatcher()
   
  ]);
}

export default rootSaga;