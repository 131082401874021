import React, { useState, useEffect } from 'react';
import '../../css/Sucessmsg.css';

function SuccessMessage({ message }) {
 
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);
  useEffect(() => {
    console.log(message,"success");
    if (message !== '') {
      setShowSuccessMessage(true);
      setTimeout(() => {
        setShowSuccessMessage(false); 
      }, 3000); 
    }
  }, [message]);

  return (
    <div>
      
      <div className={`success-message-container ${showSuccessMessage ? 'show-success-message' : ''}`}>
        {message}

      </div>
    </div>
  );
}

export default SuccessMessage;