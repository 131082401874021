import React, { useEffect, useState } from 'react';
import { Container, Form, Spinner, Stack, Table, } from 'react-bootstrap';
import { addDocumentType, getDocumentType } from '../../API/document';
import CustomPagination from '../../functions/pagination';
import { FormButton, InputField, ModalComponent } from '../../component';
import '../../component/TableComponent/index';
import SuccessMessage from '../../component/messagecomponent/successMessage';
import ErrorMessage from '../../component/messagecomponent/errorMessage';
const DocumentTypes=()=>{
  const [load,setLoad]=useState(false);
  const [message, setMessage] = useState({
    successMessage:'',
    errorMessage:''
  });
  const [set, setModel] = useState(false);
  const [modules, setModules] = useState({
    moduleType:'',
    moduleCode:'',
    active:false,
    courseLevel:false,
    dup:false,
    ThreeH:false,
    stpl:false,
    sm:false,
    tp:false,
    ts:false,
    Exam:false
  })
  const [rows, setRows] = useState([{ documentId: '',documentType:'', documentCode: "", Active: false, courseLevelDoc: false,DEF_Duplex: false,DEF_3Hole:false,DEF_Stapled:false,DEF_StudentManual:false,DEF_Tutorpack:false,DEF_TutorSI:false,DEF_Exam:false}]);
  const json = [
    {"name": "Document Id"},
    {"name": "Document Type"},
    {"name": "Document Code"},
    {"name": "Active"},
    {"name": "Course Level"},
    {"name": "DUP"},
    {"name": "3H"},
    {"name": "STPL"},
    {"name": "SM"},
    {"name": "TP"},
    {"name": "TS"},
    {"name": "Exam"},
    {"name": "Edit"},
  ]
    useEffect(()=>{
      getDoc();
    },[])  
  
    const getDoc =async()=>{
      setLoad(true);
      const res= await getDocumentType();
      setLoad(false);
      console.log(res);
      if(res?.status === 200){
        const a = res?.data?.data;
      setRows(a);
    }
  }
   const [update,setUpdate]=useState(null);
    const handleChange = (e, rowIndex) => {
      console.log(e.target.value,rowIndex,rows.length-1);
      const updatedRows = [...rows];
      if (e.target.type === 'text') {
        updatedRows[rowIndex][e.target.name] = e.target.value;
      } else if (e.target.type === 'checkbox') {
        updatedRows[rowIndex][e.target.name] = e.target.checked;
      }
      console.log("updatedRows",updatedRows[rowIndex]);

      if(rows.length-1 > rowIndex){
        setUpdate(updatedRows[rowIndex]);
        setRows(updatedRows);
      }else{
        setRows(updatedRows);
      }
  
    
    };
    
    

      //----------------------------Modal ------------------------------------//
      const handleChanges = (e) => {
        console.log(e.target.type);
           if(e.target.type=== "text"){
            setModules({
              ...modules,
              [e.target.name]: e.target.value,
            });
           }else{
            console.log(e.target.checked);
            setModules({
              ...modules,
              [e.target.name]: e.target.checked,
            });
           }
        
            };
            const openModel = () => { setMessage({errorMessage:'',successMessage:''});setModel(true) }
            const closeModel = () => { setModel(false);setModules({
              moduleCode:"",
              moduleType:'',
              active:false
            })}
          
            function body(){
              return<>
              <Container>
                <div>
                <InputField
                      label="Document Type"
                      name="documentType"
                      value={modules?.documentType}
                      type='text'
                      // required
                      placeholder='Enter Document Type'
                      // value={inputValue}
                      onChange={handleChanges}
                    />
                        <InputField
                      label="Document Code"
                      name="documentCode"
                      value={modules?.documentCode}
                      type='text'
                      // required
                      placeholder='Enter Document Code'
                      // value={inputValue}
                      onChange={handleChanges}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'2px 10px'}}>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='active' checked={modules?.active} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>Active</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='courseLevel' checked={modules?.courseLevel} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>Course Level</span>
                      </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'2px 10px'}}>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='dup' checked={modules?.dup} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>DUP</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='ThreeH' checked={modules?.ThreeH} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>3H</span>
                      </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'2px 10px'}}>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='stpl' checked={modules?.stpl} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>Stpl</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='sm' checked={modules?.sm} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>SM</span>
                      </div>
                    </div>

                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'2px 10px'}}>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='tp' checked={modules?.tp} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>TP</span>
                      </div>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='ts' checked={modules?.ts} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>TS</span>
                      </div>
                    </div>
                    <div style={{ display: 'flex', justifyContent: 'space-between', margin:'2px 10px'}}>
                      <div style={{ display: 'flex', flexDirection: 'row',width:'100%'}}>
                        <Form.Check aria-label="option 1" type='checkbox' name='Exam' checked={modules?.Exam} onChange={handleChanges} />
                        <span style={{ color: 'black', marginLeft: '6px' }}>Exam</span>
                      </div>
                      
                    </div>
                               
                </div>
              </Container>
              </>
            }
            
            function footer (){
              return(
                <div>
                  <FormButton
                  variant="primary"
                  onClick={() => handleEdit()}
                  width='160px'
                >
                  Save
                </FormButton>
                </div>
              )
            }
            function header (){
              return<>
             <Stack direction="horizontal" gap={2}>
                 <h3>Add Document Type</h3>
              </Stack>
              </>
            }
            




   
    const handleEdit=async(data)=>{
      const maxDocumentId = Math.max(...rows.map((row) => row.documentId));
      console.log(maxDocumentId,"last id");

  
      const newDocumentId = rows.length + 1;
      const body=
        {
          documentId: newDocumentId,
          documentType: modules?.documentType,
          documentCode: modules?.documentCode,
          Active: modules?.active,
          courseLevelDoc: modules?.courseLevel,
          DEF_Duplex: modules?.dup,
          DEF_3Hole: modules?.ThreeH,
          DEF_Stapled: modules?.stpl,
          DEF_StudentManual: modules?.sm,
          DEF_Tutorpack: modules?.tp,
          DEF_TutorSI: modules?.ts,
          DEF_Exam:modules?.Exam
         }
         setLoad(true);
      const result = await addDocumentType(body);
      setLoad(false);
     if(result?.status === 200){
      closeModel();
      setMessage({
        message,
        successMessage:result?.data?.message
      })
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
      getDoc();
      setRows([...rows,{ documentId: '',documentType:'', documentCode: "", Active: false, courseLevelDoc: false,DEF_Duplex: false,DEF_3Hole:false,DEF_Stapled:false,DEF_StudentManual:false,DEF_Tutorpack:false,DEF_TutorSI:false,DEF_Exam:false}]);
     }else{
      setMessage({
        message,
        errorMessage:result?.response?.data?.message
    })
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
     }
    }


    const handleEdits=async(data)=>{
     
      const body=
        {
          id:data?._id,
          documentId: data?.documentId,
          documentType: data?.documentType,
          documentCode: data?.documentCode,
          Active: data?.Active,
          courseLevelDoc: data?.courseLevelDoc,
          DEF_Duplex: data?.DEF_Duplex,
          DEF_3Hole: data?.DEF_3Hole,
          DEF_Stapled: data?.DEF_Stapled,
          DEF_StudentManual: data?.DEF_StudentManual,
          DEF_Tutorpack: data?.DEF_Tutorpack,
          DEF_TutorSI: data?.DEF_TutorSI,
          DEF_Exam:data?.DEF_Exam
         }
         setLoad(true);
      const result = await addDocumentType(body);
      setLoad(false);
     if(result?.status === 200){
      setMessage({
        message,
        successMessage:result?.data?.message
      })
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
      getDoc();
      setUpdate(null);
      setRows([...rows,{ documentId: '',documentType:'', documentCode: "", Active: false, courseLevelDoc: false,DEF_Duplex: false,DEF_3Hole:false,DEF_Stapled:false,DEF_StudentManual:false,DEF_Tutorpack:false,DEF_TutorSI:false,DEF_Exam:false}]);
     }else{
      setMessage({
        message,
        errorMessage:result?.response?.data?.message
    })
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
     }
    }



return(
    <div>
              {load === true && (
      <div className="spinner-container">
      <div className="text-center">
      <Spinner
        animation="border"
        role="status"
        style={{
          borderWidth: '2px', // Increase border width for a thicker circle
          width: '60px',   // Set the desired width
          height: '60px',  // Set the desired height (equal to width for a circle)
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div>Loading...</div>
    </div>
    </div>
    )}
            <SuccessMessage message={message?.successMessage || ''}/>
        <ErrorMessage message={message?.errorMessage || ''} />
        <ModalComponent
          title={header}
          status={set}
          onHide={closeModel}
          footer={footer}
          body={body}
          animation={true}
          size={'md'}
        />
         <div style={{width:'100%',marginBottom:'10px'}}>
        <FormButton
          variant="primary"
          onClick={() => openModel()}
          width='260px'
        >
          Add New Document Type
        </FormButton>

        </div>
   <div
    className="history-container"
      style={{
        height: '60vh',
        overflow: 'auto',
        border: '0.5px solid gray',
        marginBottom: '1rem',
        width: '100%',
      }}
    >
  
          <Table striped bordered className="custom-table">
      <thead  thead style={{ backgroundColor: '#000F3F',position:'sticky',top:'-0.1px',marginTop:'-1px',zIndex:'888'}}>
        <tr >
         {json?.map((item,index)=>(
          <th  key={index} style={{backgroundColor: '#000F3F',color: 'white',whiteSpace: 'nowrap',}}>{item?.name}</th>
         ))} 
        </tr>
      </thead>
  
        <tbody >
          {rows?.map((row, index) => (
                <tr>
                  
      <td>{row?.documentId}</td>
      <td><input value={row.documentType} name='documentType' type='text' onChange={(e) => handleChange(e, index, "text")} /></td>
     <td><input value={row.documentCode} name='documentCode' type='text' onChange={(e) => handleChange(e, index, "text")} /></td>

      <td> <Form.Check aria-label="option 1" type='checkbox' name='Active' checked={row?.Active} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='courseLevelDoc' checked={row?.courseLevelDoc} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='DEF_Duplex' checked={row?.DEF_Duplex} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='DEF_3Hole' checked={row?.DEF_3Hole} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='DEF_Stapled' checked={row?.DEF_Stapled} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='DEF_StudentManual' checked={row?.DEF_StudentManual} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='DEF_Tutorpack' checked={row?.DEF_Tutorpack} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='DEF_TutorSI' checked={row?.DEF_TutorSI} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td> <Form.Check aria-label="option 1" type='checkbox' name='DEF_Exam' checked={row?.DEF_Exam} onChange={(e) => handleChange(e, index, "check")}/></td>
      <td>
                  {update && update.documentId === row.documentId ? (
                    <button onClick={() => handleEdits(update)} style={{backgroundColor:'green',border:'none',color:'white'}}>Save</button>
                  ) : <button style={{backgroundColor:'lightgreen',border:'none',color:'white'}} disabled>Save</button>}
                </td> 
        </tr>
           
          ))}
         
      {/* {rows.length > 0 && (
    <>
            {rows[rows.length - 1].documentType !== '' && rows[rows.length - 1].documentCode !== '' && (
              <tr>
              <td colSpan="11">
              <button onClick={() => handleEdit(rows[rows.length - 1])}>Add Document Type</button>
              </td>
              </tr>
            )}
   </>
        )} */}
         </tbody>
    
        </Table>

        
  {/* <div style={{ display:'flex',flexDirection:'row',justifyContent:'right',marginTop:-50}}>
  <FormButton>Save</FormButton>
  </div>   */}

    </div>
  
  </div>
);
};

export default DocumentTypes;