import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import '../assets/ModuleHistory.css';
import { History } from '../API/document';
import moment from 'moment-timezone';

const DocumentHistory = () => {
  const location = useLocation();
  const [historyData, setHistoryData]=useState([]);
  const { ids } = location.state || {};
  console.log("kkk",ids);
  

useEffect(()=>{
  docsHistory(ids)
},[ids])
  const docsHistory=async(ids)=>{
    try{
      const data = {
        id:ids
      }
     const result = await History(data);
     setHistoryData(result?.data)
     console.log("resssssss",result?.data.map((item)=>item?.revision));
    }catch(err){
        console.log(err);
    }
  }



  return (
    <div>   
      <h1>History</h1>
      <div className="history-container">
        <div style={{maxHeight: 'calc(100vh - 200px)', paddingRight:"5px"}}>
          {historyData?.map((item, index) => (
            <div className="history-item" key={item.revision._id}>
              <div className="editor-name">Editor: {item?.revision?.changedBy}</div>
              <div className="date-time">Doc Title: {item?.revision?.docTitles}</div>
              <div className="date-time">
                Date: {moment.tz(item?.revision?.date, "America/Toronto").format("YYYY-MM-DD")}
                <br />
                Time: {moment.tz(item?.revision?.date, "America/Toronto").format("HH:mm:ss")}
              </div>
              <div className="date-time">Revision Code: {item?.revision?.version}</div>
              <div className="date-time">Change log: {item?.revision?.logs || "N/A"}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DocumentHistory;
