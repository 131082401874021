// import axios from 'axios';
import apiInstance from "./baseApi"

export const login = async (user) => {
    console.log(user,"klkkk");
try{
    const response = await apiInstance.post('auth/login',user);
      console.log(response.data);
  
      return response.data;
} catch(err){
    console.log(err); 
    return err;
}
}

export const register = async (userDtail,token) => {
    try{
        const heder={
            headers: {
                "authorization":token
            }
        }
        const response = await apiInstance.post('auth/register',userDtail,heder);
          console.log(response.data);
          return response.data;
    } catch(err){
        return err;
    }
    }

    export const getUsers = async (currentPage,token) => {
        try{
            console.log("hghghg",currentPage,token);
            const heder={
                headers: {
                    "authorization":token
                }
            }
            const response = await apiInstance.get(`auth/get-User?page=${currentPage}`,heder);
              console.log(response.data);
              return response.data;
        } catch(err){
            return err;
        }
        }