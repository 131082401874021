
import React, { useEffect, useState } from 'react';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { CheckBoxComponent } from '../component';

function TimeTable() {
  const [selectedOptions, setSelectedOptions] = useState([]);
  const [rows, setRows] = useState([{ selectedOption: '', name: '', age: '', height: false }]);
  const op = [
    { name: 'family1', value: 'family1' },
    { name: 'family2', value: 'family2' },
    { name: 'family3', value: 'family3' },
  ];

  useEffect(() => {
    // Set the initial rows with one empty row
    setRows([{ selectedOption: '', name: '', age: '', height: false }]);
  }, []);

  const handleDragEnd = (result) => {
    if (!result.destination) {
      return;
    }

    const items = Array.from(rows);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);

    setRows(items);
  };

  const handleInputChange = (e, index, field) => {
    if (index < 0 || index >= rows.length) {
      console.error(`Invalid index: ${index}`);
      return;
    }

    const updatedRows = [...rows];

    if (field === "height") {
      updatedRows[index][field] = e.target.checked;
    } else {
      updatedRows[index][field] = e.target.value;
    }

    setRows(updatedRows);
  };

  const handleSelectChange = (index, value, field) => {
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: value,
    };

    // Keep track of selected options
    const selectedOptions = updatedRows.map((row) => row.selectedOption);

    setSelectedOptions(selectedOptions);

    if (rows.length - 1 === index) {
      // Add a new empty row
      setRows([...updatedRows, { selectedOption: '', name: '', age: '', height: false }]);
    }
  };

  function submit() {
    console.log("Rows:", rows);
  }

  return (
    <div>
      <DragDropContext onDragEnd={handleDragEnd}>
        <table>
          <thead>
            <tr>
              <th>jldkjf</th>
              <th>hncksdjh</th>
              <th>hnckjh</th>
              <th>jjjjjjj</th>
            </tr>
          </thead>
          <Droppable droppableId="droppable">
            {(provided) => (
              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                {rows.map((row, index) => (
                  <Draggable key={index} draggableId={String(index)} index={index} isDragDisabled={index === rows.length - 1}>
                    {(provided) => (
                      <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
                        <td>
                          <select
                            onChange={(e) => handleSelectChange(index, e.target.value, "selectedOption")}
                            value={row.selectedOption}
                          >
                            <option value="">Select an object</option>
                            {op.map((item, itemIndex) => (
                              <option
                                key={itemIndex}
                                value={item.value}
                                disabled={selectedOptions.includes(item.value)}
                              >
                                {item.name}
                              </option>
                            ))}
                          </select>
                        </td>
                        <td>
                          <input
                            type="text"
                            placeholder="Name"
                            value={row.name}
                            onChange={(e) => handleInputChange(e, index, 'name')}
                          />
                        </td>
                        <td>
                          <input
                            type="number"
                            placeholder="Age"
                            value={row.age}
                            onChange={(e) => handleInputChange(e, index, 'age')}
                          />
                        </td>
                        <td>
                          <CheckBoxComponent
                            label="Active"
                            checked={row.height}
                            onChange={(e) => handleInputChange(e, index, "height")}
                          />
                        </td>
                      </tr>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </tbody>
            )}
          </Droppable>
        </table>
      </DragDropContext>

      <button onClick={submit}>Add Row</button>
      <pre>{JSON.stringify(rows, null, 2)}</pre>
      Working In Progress
    </div>
  );
}

export default TimeTable;







// import html2canvas from "html2canvas";
// import jsPdf from "jspdf";

// // import "./styles.css";

// function TimeTable() {
//   const printPDF = () => {
//     const domElement = document.getElementById("gh");
//     html2canvas(domElement, {
//       onclone: document => {
//         document.getElementById("print").style.visibility = "hidden";
//       }
//     }).then(canvas => {
//       const imgData = canvas.toDataURL("image/png");
//       const pdf = new jsPdf();
//       pdf.addImage(imgData, "JPEG", 10, 10);
//       pdf.save(`${new Date().toISOString()}.pdf`);
//     });
//   };

//   return (
//     <div className="App">
//       <h1>Generate PDF</h1>
//       <p>Create a screenshot from the page, and put it in a PDF file.</p>
//       <p style={{ color: "red" }}>
//         *Open this page in new window and press the button.
//       </p>
//       <p id="gh">joibjhgbjg</p>
//       <button id="print" onClick={printPDF}>
//         PRINT
//       </button>
//     </div>
//   );
// }

// export default TimeTable;


// import React from 'react';
// import jsPDF from 'jspdf';

// class PDFGenerator extends React.Component {
//   generatePDF = () => {
//     const doc = new jsPDF();
//     const text = 'Your text that will be printed on the PDF';
//     doc.text(text, 10, 10); // Adjust coordinates as needed
//     doc.save('generated.pdf');
//   };

//   componentDidMount() {
//     this.generatePDF();
//   }

//   render() {
//     return null;
//   }
// }

// export default PDFGenerator;














