import React, { useEffect, useState } from 'react';
import { Container, Form, Spinner, Stack, Table, } from 'react-bootstrap';
import { addModuleType,getModuleType } from '../../API/moduleApi';
import CustomPagination from '../../functions/pagination';
import { FormButton, InputField, ModalComponent } from '../../component';
import '../../assets/Table.css';
import SuccessMessage from '../../component/messagecomponent/successMessage';
import ErrorMessage from '../../component/messagecomponent/errorMessage';
const ModuleTypes=()=>{
    const [load,setLoad]=useState(false);
    const [set, setModel] = useState(false);
    const [modules, setModules] = useState({
      moduleType:'',
      moduleCode:'',
      active:false
    })
    const [message, setMessage] = useState({
        successMessage:'',
        errorMessage:''
      });
  const [rows, setRows] = useState([{ moduleId: '',moduleType:'', moduleCode: "", active: false, }]);
  const json = [
    {"name": "Module Id"},
    {"name": "Module Type"},
    {"name": "Module Code"},
    {"name": "Active"},
    {"name": "Edit"},
  ]


    useEffect(()=>{
      getDoc();
    },[])  
  
    //----------------------------Modal ------------------------------------//
    const handleChanges = (e) => {
console.log(e.target.type);
   if(e.target.type=== "text"){
    setModules({
      ...modules,
      [e.target.name]: e.target.value,
    });
   }else{
    console.log(e.target.checked);
    setModules({
      ...modules,
      [e.target.name]: e.target.checked,
    });
   }

    };
    const openModel = () => { setMessage({errorMessage:'',successMessage:''});setModel(true) }
    const closeModel = () => { setModel(false);setModules({
      moduleCode:"",
      moduleType:'',
      active:false
    })}
  
    function body(){
      return<>
      <Container>
        <div>
        <InputField
              label="Module Type"
              name="moduleType"
              value={modules?.moduleType}
              type='text'
              // required
              placeholder='Enter Module Type'
              // value={inputValue}
              onChange={handleChanges}
            />
                <InputField
              label="Module Code"
              name="moduleCode"
              value={modules?.moduleCode}
              type='text'
              // required
              placeholder='Enter Module Code'
              // value={inputValue}
              onChange={handleChanges}
            />
            <div style={{display:'flex',flexDirection:'row',marginLeft:'10px'}}>
            <Form.Check aria-label="option 1" type='checkbox' name='active' checked={modules?.active} onChange={handleChanges}/>
            <span style={{color:'black',marginLeft:'6px'}}>Active</span>
            </div>             
        </div>
      </Container>
      </>
    }
    
    function footer (){
      return(
        <div>
          <FormButton
          variant="primary"
          onClick={() => handleEdit()}
          width='160px'
        >
          Save
        </FormButton>
        </div>
      )
    }
    function header (){
      return<>
     <Stack direction="horizontal" gap={2}>
         <h3>Add Module Type</h3>
      </Stack>
      </>
    }
    
    const getDoc =async()=>{
        setLoad(true);
      const res= await getModuleType();
      setLoad(false);
      console.log(res);
      if(res?.status === 201){
        const a = res?.data?.data;
      setRows(a);
    }
  }
   const [update,setUpdate]=useState(null);
    const handleChange = (e, rowIndex) => {
      console.log(e.target.value,rowIndex,rows.length-1);
      const updatedRows = [...rows];
      if (e.target.type === 'text') {
        updatedRows[rowIndex][e.target.name] = e.target.value;
      } else if (e.target.type === 'checkbox') {
        updatedRows[rowIndex][e.target.name] = e.target.checked;
      }
      console.log("updatedRows",updatedRows[rowIndex]);

      if(rows.length-1 > rowIndex){
        setUpdate(updatedRows[rowIndex]);
        setRows(updatedRows);
      }else{
        setRows(updatedRows);
      }
  
    
    };
    
    
   
    const handleEdit=async()=>{
      const maxDocumentId = Math.max(...rows.map((row) => row.moduleId));
      console.log("row",rows.length);
      console.log(maxDocumentId,"last id");

  
      const newDocumentId = rows.length + 1;
      const body=
        {
          moduleId: newDocumentId,
          moduleType: modules?.moduleType,
          moduleCode: modules?.moduleCode,
          active: modules?.active,
         }
         setLoad(true);
      const result = await addModuleType(body);
      console.log(result);
      setLoad(false);
     if(result?.status === 200){
       closeModel();
        setMessage({
            message,
            successMessage:result?.data?.message
          })
          setTimeout(() => {
            setMessage({
              successMessage:""
            })
          }, 4000);
      getDoc();
      setRows([...rows,{ moduleId: '',moduleType:'', moduleCode: "", active: false}]);
     }else{
        console.log(result);
        setMessage({
            message,
            errorMessage:result?.response?.data?.message
        })
          setTimeout(() => {
            setMessage({
              successMessage:""
            })
          }, 4000);
     }
    }


    const handleEdits=async(data)=>{
      const body=
        {
          id:data?._id,
          moduleId: data?.moduleId,
          moduleType: data?.moduleType,
          moduleCode: data?.moduleCode,
          active: data?.active,
  
         }
      setLoad(true);
      const result = await addModuleType(body);
      setLoad(false);
      if(result?.status === 200){
        setMessage({
            message,
            successMessage:result?.data?.message
          })
          setTimeout(() => {
            setMessage({
              successMessage:""
            })
          }, 4000);
      getDoc();
      setRows([...rows,{ moduleId: '',moduleType:'', moduleCode: "", active: false}]);
     }else{
        console.log(result);
        setMessage({
            message,
            errorMessage:result?.response?.data?.message
        })
          setTimeout(() => {
            setMessage({
              successMessage:""
            })
          }, 4000);
     }
    }



return(
    <div> 
  
          {load === true && (
      <div className="spinner-container">
      <div className="text-center">
      <Spinner
        animation="border"
        role="status"
        style={{
          borderWidth: '2px', // Increase border width for a thicker circle
          width: '60px',   // Set the desired width
          height: '60px',  // Set the desired height (equal to width for a circle)
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div>Loading...</div>
    </div>
    </div>
    )}  
        <SuccessMessage message={message?.successMessage || ''}/>
        <ErrorMessage message={message?.errorMessage || ''} />
        <ModalComponent
          title={header}
          status={set}
          onHide={closeModel}
          footer={footer}
          body={body}
          animation={true}
          size={'md'}
        />
        <div style={{width:'100%',marginBottom:'10px'}}>
        <FormButton
          variant="primary"
          onClick={() => openModel()}
          width='260px'
        >
          Add New Module Type
        </FormButton>

        </div>
   <div
    className="history-container"
      style={{
        height: '60vh',
        overflow: 'auto',
        border: '0.5px solid gray',
        marginBottom: '1rem',
        width: '100%',
      }}
    >
  
  <Table striped bordered className="custom-table">
      <thead  thead style={{ backgroundColor: '#000F3F',position:'sticky',top:'-0.1px',marginTop:'-1px',zIndex:'888'}}>
      
        <tr >
         {json?.map((item,index)=>(
          <th  key={index} style={{backgroundColor: '#000F3F',color: 'white',whiteSpace: 'nowrap',}}>{item?.name}</th>
         ))} 
              {/* {json?.map((item, index) => (
      <th key={index} style={{ backgroundColor: '#000F3F', color: 'white', whiteSpace: 'nowrap' }}>
        {item?.name}
      </th>
    ))} */}
        </tr>
      </thead>
  
        <tbody >
          {rows?.map((row, index) => (
                <tr>
                  
      <td>{row?.moduleId}</td>
      <td><input value={row.moduleType} name='moduleType' type='text' onChange={(e) => handleChange(e, index, "text")} /></td>
     <td><input value={row.moduleCode} name='moduleCode' type='text' onChange={(e) => handleChange(e, index, "text")} /></td>

      <td> <Form.Check aria-label="option 1" type='checkbox' name='active' checked={row?.active} onChange={(e) => handleChange(e, index, "check")}/></td>
     
      <td>
                  {update && update.moduleId === row.moduleId ? (
                    <button onClick={() => handleEdits(update)} style={{backgroundColor:'green',border:'none',color:'white'}}>Save</button>
                  ) : 
                  (
                    <button disabled style={{backgroundColor:'lightgreen',border:'none',color:'white'}}>Save</button>
                  )}
                </td> 
        </tr>
           
          ))}
         
      {/* {rows.length > 0 && (
    <>
            {rows[rows.length - 1].moduleType !== '' && rows[rows.length - 1].moduleCode !== '' && (
              <tr>
              <td colSpan="11">
              <button onClick={() => handleEdit(rows[rows.length - 1])}>Add Module Type</button>
              </td>
              </tr>
            )}
   </>
        )} */}
         </tbody>
    
        </Table>

        
  {/* <div style={{ display:'flex',flexDirection:'row',justifyContent:'right',marginTop:-50}}>
  <FormButton>Save</FormButton>
  </div>   */}

    </div>
  
  </div>
);
};

export default ModuleTypes;