import { Table } from "react-bootstrap";
import '../../assets/Table.css';

type Column = {
  name: string;
  datan: string;
  Call?: (data: any) => React.ReactNode;
};

type Props = {
  columns: Column[];
  data?: any[]; // Make data optional
  width?: string;
  cellWidth?: string;
};

const table = (props: Props) => {
  // Ensure that props.data is an array or default to an empty array
  const data = Array.isArray(props.data) ? props.data : [];

  return (
    <div
    className="history-container"
      style={{
        height: '60vh',
        overflow: 'auto',
        border: '0.5px solid gray',
        marginBottom: '1rem',
        width: props.width || '100%',
        padding:"0px"
      }}
    >
      <Table striped bordered className="custom-table">
        <thead style={{ backgroundColor: '#000F3F',position:'sticky',top:'-0.1px',marginTop:'-1px',zIndex:'888'}}>
          <tr style={{marginTop:'-10px'}}>
            {props.columns.map((hdata, index) => (
              <th
                key={index}
                style={{
                  color: 'white',
                  whiteSpace: 'nowrap',
                  backgroundColor: '#000F3F',
                  textAlign:'left'
                }}
              >
                {hdata.name}
              </th>
            ))}
          </tr>
        </thead>
        <tbody>
          {data.map((bdata, rowIndex) => (
            <tr key={rowIndex}>
              {props.columns.map((bhdata, colIndex) => (
                <td
                  key={colIndex}
                  style={{ width: props.cellWidth || 'auto' ,alignItems:'center',alignContent:'center',textAlign:'left'}}
                >
                  {bhdata.Call
                    ? bhdata.Call(bdata)
                    : bdata[bhdata.datan]}
                </td>
              ))}
            </tr>
          ))}
        </tbody>
      </Table>
   </div>
  );
};

export default table;

// import React from 'react';
// import { Table } from 'react-bootstrap';
// import '../../assets/Table.css';

// type Column = {
//   name: string;
//   datan: string;
//   Call?: (data: any) => React.ReactNode;
// };

// type Props = {
//   columns: Column[];
//   data?: any[];
//   width?: string;
//   cellWidth?: string;
// };

// const table = (props: Props) => {
//   const data = Array.isArray(props.data) ? props.data : [];

//   return (
//     <div className="history-container" style={{ height: '60vh', overflow: 'auto', border: '0.5px solid gray', marginBottom: '1rem', width: props.width || '100%' }}>
//       <Table striped bordered className="custom-table">
//         <thead className="sticky-header">
//           <tr style={{ backgroundColor: '#000F3F' }}>
//             {props.columns.map((hdata, index) => (
//               <th key={index} style={{ backgroundColor: '#000F3F', color: 'white', whiteSpace: 'nowrap' }}>
//                 {hdata.name}
//               </th>
//             ))}
//           </tr>
//         </thead>
//         <tbody>
//           {data.map((bdata, rowIndex) => (
//             <tr key={rowIndex} className={bdata.disabled ? 'disabled-row' : ''}>
//               {props.columns.map((bhdata, colIndex) => (
//                 <td key={colIndex} style={{ width: props.cellWidth || 'auto' }}>
//                   {bhdata.Call ? (
//                     <span
//                       className={bdata.disabled ? 'disabled-button' : ''}
//                       onClick={() => !bdata.disabled && bhdata.Call(bdata)}
//                     >
//                       {bhdata.name}
//                     </span>
//                   ) : (
//                     bdata[bhdata.datan]
//                   )}
//                 </td>
//               ))}
//             </tr>
//           ))}
//         </tbody>
//       </Table>
//     </div>
//   );
// };

// export default table;


