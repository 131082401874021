import React from 'react';

function menuHeader() {
  return (
    <div style={{ height: '80px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <p style={{ color: 'white', fontSize: '20px', margin: 0 }}>QMII-ESM</p>
  </div>  
  );
};

export default menuHeader;