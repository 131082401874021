import React, { useEffect, useState } from 'react';
import { TableComponent, FormButton, ModalComponent, InputField,CustomDropdownButton,CheckBoxComponent,SelectComponent } from '../../component/index';
import { Button, Col, Container, Form, FormControl, InputGroup, Row, Spinner, Stack,Table } from 'react-bootstrap';
import CustomPagination from '../../functions/pagination';
//import table from '../../component/TableComponent';
// import BasicMenu from '../component/index';
import Select from 'react-select';
import { getAllModuleName,addCourseDetails, getAllCourse, editCourse, deleteCourse, cretePackage, studentPackpdf, tutorPackpdf, timeTablepdf, courseDownload, intigratedCourse, packList, publish, TimeTables, ModuleList, createTutorSlidePdf } from '../../API/courseApi';
//import { async } from 'q';
import SuccessMessage from '../../component/messagecomponent/successMessage';
import ErrorMessage from '../../component/messagecomponent/errorMessage';
import Package from './packegeList';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import '../../assets/Document.css';
import TimeTable from '../timeTable';
import TimeTablePackage from './timeTablePackage';
import { CloseOutlined, SearchOutlined } from '@ant-design/icons';
import ToolTipCmp from '../../component/ToolTipCmp';
import RangeDatePicker from '../../assets/Date/RangeDatePicker';
import { FaArrowDownAZ, FaArrowDownWideShort, FaArrowUpAZ, FaArrowUpShortWide, FaArrowUpZA } from "react-icons/fa6";

const Courese = () => {
  const [option,setOption]=useState([]);
  const [course,setCourse]=useState('');
  const [error,setError]=useState('');
  const [currentPage,setCurrentPage]=useState(1);
  const [totalPage, setTotalPage]=useState('');
  const [ids,setIds]=useState('');
  const [sorting,setSorting]=useState('');
  const[ser,setSer]=useState('');
  const [link,setLink]=useState('');
  const [load,setLoad]=useState(false);
const [title,setTitle]=useState('');
const [docDate, setDocDate]=useState('');
// const excludedIds = '661a3bfd135511b3fd1bc8b5,661a3c1e135511b3fd1bc8b6,661a3c43135511b3fd1bc8b7,661a3c54135511b3fd1bc8b8'.split(',');
const excludedIds ='668398ab0519740184046111,668398be0519740184046112,668398cf0519740184046113,668398e10519740184046114'.split(',');
const [collection,setCollection]=useState({
  t_pk:true,sm:true,
  tabs:true,index:false,t_sld:true,packagingList:true,courseDocumentChanges:false,exam:true
})
 const [addCourse,setAddCourse]=useState({
  course_Name:'',desc:'',status:"",active:true,notes:''
 });
  const [rows, setRows] = useState([{ moduleId: '',fileTitle:'', dup: false, threeHole: false, stpl: false,s_mnal: false,t_pk:false,t_sld:false,sort:'',quantity:'',duration:'00',tabsText:'',startTime:'',type:"",desc:"" }]);
  const [message, setMessage] = useState({
    successMessage:'',
    errorMessage:''
  });
  useEffect(() => {
    // Set the initial rows with one empty row
    setRows([{ moduleId: '',fileTitle:'', dup: false, threeHole: false, stpl: false,s_mnal: false,t_pk:false,t_sld:false,sort:'',quantity:'00',duration:'00',tabsText:'',startTime:'',type:"",desc:"" }]);
  }, []);
useEffect(()=>{
  getCourse(currentPage);
  getallModule(); 
  console.log("hhhhh",currentPage);
},[currentPage,ser,sorting])
const navigate = useNavigate();
const [modaldate,setModaldate]=useState(false);
//------------getCourse API-------------//

const getCourse=async(newPage)=>{
  setLoad(true);
  const res = await getAllCourse(newPage,ser,sorting)
  console.log("resres",res);
  setLoad(false);
  setCourse(res?.data?.data)
  setCurrentPage(res?.data?.pages?.currentPage);
  setTotalPage(res?.data?.pages?.totalPages);
}

const getallModule = async () => {
  const res = await getAllModuleName();
  
  const mappedDocuments = res?.data?.data.map((module) => ({
    label: module.name + (module.desc ? ":" + module?.desc: ""),
    // label: (module.active === true ? "Active" + "_":module.active === false ? "InActive" + "_" : "") + module.name,
    value: module?._id,
    k: module
  }));
console.log("mappedDocuments ggggggggggg",mappedDocuments);
  setOption(mappedDocuments);
};
//------------Sorting------------------//
const sortingDateandTitle=(val)=>{
 
  setSorting(val);


}
//-----------search------------------//

const search = async(e)=>{
  setSorting('');
  const a = e.target.value;
  console.log(a,"gttt");
  setSer(a);
 
}

 //--------add courses----------//

const handleChange=(e)=>{
  setError('')
    setAddCourse({
      ...addCourse,
      [e.target.name]: e.target.value,
    })
  }

  const quqntity = [
    { label: 'One', value: 'One' },
    { label: 'One per student', value: 'One per student' },
    { label: 'One per student + one', value: 'One per student + one' },
    { label: 'Two', value: 'Two' },
    { label: 'Two per student', value: 'Two per student' },
    { label: 'Zero', value: 'Zero' },
  ];
  const handleInputChange = (e, index, field) => {
    if (index < 0 || index >= rows?.length) {

      return;
    }
    const updatedRows = [...rows];
    if (field === "tabsText" || field === "duration" || field === "startTime") {
      console.log("ttttt", e.target.value)
      updatedRows[index][field] = e.target.value; 
    } else {
      updatedRows[index][field] = e.target.checked; 
    }
    setRows(updatedRows);
  };

  const [valid,setValid]=useState('')
  const handleSelectChanges = (index, selectedOption,field,fileTitle) => {
    setError('');
    console.log(selectedOption?.label, option, "jjjjjj");
    const selectedItem = option.find((item) => item.label === selectedOption?.label);
    console.log("Selected Item:", selectedItem?.k?.duration);
    
    const updatedRows = [...rows];
    updatedRows[index] = {
      ...updatedRows[index],
      [field]: selectedOption?.value,
      [fileTitle]:selectedOption?.label,
      tabsText: selectedItem?.k?.tabText,
      duration:selectedItem?.k?.duration,
      type:selectedItem?.k?.type,
      desc:selectedItem?.k?.desc
      
    };
    console.log("updatedRows",updatedRows);
    setRows(updatedRows);
    if (field === "moduleId") {
      if (selectedOption?.label === "Class Start") {
        updatedRows[index]["startTime"] = "08:00";
        updatedRows[index]["duration"] = "00";
      } else if (selectedOption?.label === "Day End") {
        updatedRows[index]["startTime"] = "17:00";
        updatedRows[index]["duration"] = "00";
      } else if (selectedOption?.label === "Lunch") {
        updatedRows[index]["startTime"] = "12:00";
        updatedRows[index]["duration"] = "30";
      } else if (selectedOption?.label === "Break") {
        updatedRows[index]["startTime"] = "10:00";
        updatedRows[index]["duration"] = "15";
      } else {
        console.log("selectedOption?.label",selectedOption?.label);
        updatedRows[index]["startTime"] = "";
        // updatedRows[index]["duration"] = "";
      }
  
      if (rows?.length - 1 === index) {
        setRows([
          ...updatedRows,
          {
            moduleId: "",
            fileTitle: "",
            dup: false,
            threeHole: false,
            stpl: false,
            s_mnal: false,
            t_pk: false,
            t_sld: false,
            sort: "",
            quantity: "",
            duration: "00",
            type:"",
            desc:"",
            tabsText: "",
            startTime: "",
          },
        ]);
      }
    }
    
  };

  const handleSelect=(value)=>{
    setError('');
    setAddCourse({
      ...addCourse,
      status:value
    })
  }


  const addCorses=async(id)=>{
 
    const ownername = localStorage.getItem("userName");
    const filteredData = rows.filter(item => item.moduleId !== "" && Object.keys(item).length > 0);
    console.log("filteredDatafilteredData",filteredData);
    if(addCourse?.course_Name === ""){
      setError("Course Name is required");
    }else if(addCourse?.status === ""){
      setError("Status is required");
    }else if(filteredData[0]?.fileTitle != "Class Start"){
      setError("Select Class Start");
    }else{
   
  try{
    console.log(addCourse._id);
    if(!addCourse._id){
      const data={
        course_Name:addCourse?.course_Name,
        desc: addCourse?.desc,
        status: addCourse?.status,
        notes: addCourse?.notes,
        active: addCourse?.active,
        tutorBox: addCourse?.tutorBox,
        studentManual: addCourse?.studentManual,
        tabs: addCourse?.tabs,
        index: addCourse?.index,
        exam: addCourse?.exam,
        tutorSlides: addCourse?.tutorSlides,
        courseDocumentChanges: addCourse?.courseDocumentChanges,
        packagingList: addCourse?.packagingList,
        editorName: ownername,
        module:filteredData
    }
    setLoad(true);
    const result = await addCourseDetails(data);
    setLoad(false);
    if(result?.status === 201){
      closeModel();
   
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
      setMessage({
        message,
        successMessage:result?.data?.message
      });
      setAddCourse({
        course_Name:'',desc:'',status:"",active:true,notes:'',tutorBox:true,studentManual:true,
       tabs:true,index:false,tutorSlides:true,packagingList:true,courseDocumentChanges:true,exam:true
      })
      getCourse(currentPage);
  
    }else{
      closeModel();
      setMessage({
        message,
        errorMessage:"Somthing went wrong"
      })
    }
    }else{
      const data={
        id:addCourse?._id,
        course_Name:addCourse?.course_Name ,
        desc: addCourse?.desc,
        status: addCourse?.status,
        notes: addCourse?.notes,
        active: addCourse?.active,
        tutorBox: addCourse?.tutorBox,
        studentManual: addCourse?.studentManual,
        tabs: addCourse?.tabs,
        index: addCourse?.index,
        exam: addCourse?.exam,
        tutorSlides: addCourse?.tutorSlides,
        courseDocumentChanges: addCourse?.courseDocumentChanges,
        packagingList: addCourse?.packagingList,
        editorName: ownername,
        module:filteredData
    }
    setLoad(true);
    const result = await editCourse(data);
    setLoad(false);
    console.log(result?.status);
    if(result?.status === 201){
      closeModel();
      setTimeout(() => {
        setMessage({
          successMessage:""
        })
      }, 4000);
      setMessage({
        message,
        successMessage:result?.data?.message
      })
      getCourse(currentPage);

    }else{
      closeModel();
      setMessage({
        message,
        errorMessage:"Somthing went wrong"
      })
    }
    }
  }catch(err){
    console.log("hhh",err);
  }
}
  }


//-------delete course---------//

const deleteCourses=async()=>{
  console.log(ids,"hhh");
  if(ids){
    setLoad(true);
    const result = await deleteCourse(ids);
    setLoad(false);
    console.log(result,";jih");
    if(result?.status === 201){
      setModal1(false);
          setMessage({
            successMessage:result?.data?.message
          })
       
          setTimeout(() => {
            setMessage({
              successMessage:""
            })
          }, 4000);

          getCourse(currentPage);

    }else{
          setMessage({
            message,
            errorMessage:"Somthing went wrong"
          })
          getCourse(currentPage);
          setTimeout(() => {
            setMessage({
              successMessage:""
            })
          }, 4000);
    }
  }else{
    console.log("id required");
  }
}
//---------------pdfgenerate----------------//
const smPack=async()=>{
  
  try{
    setLoad(true);
    const moduleIdsArray = await Promise.all(rows.map(async (row) => {
      return row.moduleId;
    }));
    console.log("moduleIdsArray",rows._id);
   
  // const data={
  //     moduleIds: moduleIdsArray.slice(0, -1),
  //     course:addCourse?.course_Name
  // }
  const data={
    moduleIds: addCourse._id,
    course:addCourse?.course_Name
}
  const result = await studentPackpdf(data);
  setLoad(false)
  console.log("result",result?.data?.donloadlink);
 
  if (result?.data?.donloadlink) {
    window.open(`${result?.data?.donloadlink}`, '_blank');
  }

  }catch(err){
    console.log(err);
  }

}
const tpPack=async()=>{
  try{
    setLoad(true);
    // const moduleIdsArray = await Promise.all(rows.map(async (row) => {
    //   return row.moduleId;
    // }));
    const data={
      moduleIds: addCourse._id,
      course:addCourse?.course_Name
  }
 
   
  // const data={
  //     moduleIds: moduleIdsArray.slice(0, -1),
  //     course:addCourse?.course_Name
  // }
  const result = await tutorPackpdf(data);
  setLoad(false)
  console.log("result",result?.data?.donloadlink);
 
  if (result?.data?.donloadlink) {
    window.open(`${result?.data?.donloadlink}`,'_blank');
  }

  }catch(err){
    console.log(err);
  }
}

const tsPack=async()=>{
  try{
    setLoad(true);
    // const moduleIdsArray = await Promise.all(rows.map(async (row) => {
    //   return row.moduleId;
    // }));
    const data={
      moduleIds: addCourse._id,
      course:addCourse?.course_Name
  }
 
   
  // const data={
  //     moduleIds: moduleIdsArray.slice(0, -1),
  //     course:addCourse?.course_Name
  // }
  const result = await createTutorSlidePdf(data);
  setLoad(false)
  console.log("result",result?.data?.donloadlink);
 
  if (result?.data?.donloadlink) {
    window.open(`${result?.data?.donloadlink}`,'_blank');
  }else{
    setMessage({
      message,
      errorMessage:"Somthing went wrong"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing went wrong"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }
}

const tmPack=async()=>{
  try{
    setLoad(true);
    const moduleIdsArray = await Promise.all(rows.map(async (row) => {
      return row.moduleId;
    }));
    console.log("moduleIdsArray",moduleIdsArray);
   
  const data={
      moduleIds: moduleIdsArray.slice(0, -1),
      course:addCourse?.course_Name
  }
  const result = await timeTablepdf(data);
  setLoad(false)
  console.log("result",result?.data?.donloadlink);

  if (result?.data?.donloadlink) {
    window.open(`${result?.data?.donloadlink}`,'_blank');
  }else{
    setMessage({
      message,
      errorMessage:"Somthing went wrong"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }
}

const listPack=async()=>{
  console.log("bbb",addCourse?._id);
  try{
    setLoad(true);
    // const moduleIdsArray = await Promise.all(rows.map(async (row) => {
    //   return row.moduleId;
    // }));
    // console.log("moduleIdsArray",moduleIdsArray);
   
   
  // const data={
  //     moduleIds: moduleIdsArray.slice(0, -1),
  //     course:addCourse?.course_Name
  // }
  const data={
    moduleIds: addCourse?._id,
}
  const result = await packList(data);
  setLoad(false)
  console.log("result",result?.data?.downloadLink);
 
  if (result?.data?.downloadLink) {
    window.open(`${result?.data?.downloadLink}`,'_blank');
   
  }else{
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

}

const downloadCoursedoc=async(rowData)=>{
  console.log(rowData);
  try{
    setLoad(true);
  const data={
      moduleIds:rowData?._id,
      courseName:rowData?.course_Name
  }
  const result = await intigratedCourse(data);
  setLoad(false)
  console.log("result",result?.data?.donloadlink);

  if (result?.data?.donloadlink) {
    window.location.href =`${result?.data?.donloadlink}`;
  }else{
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }
}

const downloadCourse=async()=>{
  console.log(addCourse);
  try{
    setLoad(true);
  const data={
      moduleIds:addCourse?._id,
      courseName:addCourse?.course_Name
  }
  const result = await courseDownload(data);
  setLoad(false)
  console.log("result",result?.data?.donloadlink);

  if (result?.data?.donloadlink) {
    window.location.href =`${result?.data?.donloadlink}`;
  }else{
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }
}

// const PublishCourse=async()=>{

//   const trueKeys = Object.keys(collection).filter(key => collection[key]);
// console.log(trueKeys);
//   try{
//     setLoad(true);
//   const data = {
//     id: addCourse?._id,
//     name: addCourse?.course_Name,
//     collection :trueKeys
// }


//   const result = await publish(data);
//   setLoad(false)
//   console.log("result",result);
 
//   if (result?.data?.downloadLink) {
//     window.open(`${result?.data?.downloadLink}`,'_blank');
   
//   }

//   }catch(err){
//     console.log(err);
//   }

// }

const PublishCourse = async () => {
  const trueKeys = Object.keys(collection).filter(key => collection[key]);
console.log(trueKeys);
  // Check if at least one key is selected
  if (trueKeys.length === 0) {
    console.log("Please select at least one key.");
    return;
  }

  try {
    let result;
    setLoad(true);
    const data = {
      id: addCourse?._id,
      name: addCourse?.course_Name,
      collection: trueKeys,
      startDate:docDate?.startDate,
      endDate:docDate?.endDate
    };
if(data.collection.find((item)=>item === "courseDocumentChanges")){
  if (data.startDate === undefined|| data.endDate === undefined) {
    setMessage({
      message,
      errorMessage:"Select Start date and End date"
    })
    setTimeout(() => {
      setMessage({
        successMessage:""
      })
    }, 4000);
  }else{
     result = await publish(data);
  }

}else{
  console.log("hh");
  result = await publish(data);
}
     setDocDate('');
    setLoad(false);
    if (result?.data?.downloadLink) {
      window.open(`${result?.data?.downloadLink}`, '_blank');
    }else{
      setMessage({
        message,
        errorMessage:"Somthing went wrong or Net Error"
      });
      setTimeout(()=>{
        setMessage({
          message,
          errorMessage:""
        });
      },5000)
    }
    setError("")
  } catch (err) {
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }
};



const TimeTableGenerate=async(state,id)=>{
  try{
    setLoad(true);
  const data = {
    id: addCourse?._id ? addCourse?._id : id,
}
console.log("stategggggggggggg",state);
  const status = state;
  const result = await TimeTables(data,status);
  setLoad(false)
  console.log("result",result);
 
  if (result?.data?.downloadLink) {
    window.open(`${result?.data?.downloadLink}`,'_blank');
   
  }
  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

}


const ModuleListGenerate=async()=>{
  try{
    setLoad(true);
  const data = {
    courseId: addCourse?._id,
}

  const result = await ModuleList(data);
  setLoad(false)
  console.log("result",result);
 
  if (result?.data?.donloadlink) {
    window.open(`${result?.data?.donloadlink}`,'_blank');
   
  }else{
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

  }catch(err){
    setMessage({
      message,
      errorMessage:"Somthing went wrong or Net Error"
    });
    setTimeout(()=>{
      setMessage({
        message,
        errorMessage:""
      });
    },5000)
  }

}

//------Filterv Date--------------//

const onDate=(data)=>{
  console.log("fsdfgfdgdfgdfgdf",data);
  const dat= data.split("#")
  console.log("hhh",dat);
  const dates = {
    startDate:dat[0],
    endDate:dat[1]
  }
  setDocDate(dates);


  }
  const savedDate=()=>{
    console.log(docDate);
    setModaldate(false)
  }

//----------drag----------//
const handleDragEnd = (result) => {
  if (!result.destination) {
    return;
  }

  const items = Array.from(rows);
  const [reorderedItem] = items.splice(result.source.index, 1);
  items.splice(result.destination.index, 0, reorderedItem);

  setRows(items);
};




  const menuItems = [
    { label: 'Action', link: '#' },
    { label: 'Action', link: '#' },
    { label: 'Action', link: '#' }
  ];

  var columns = [
  
    {
      datan: 'course_Name',
      // name: 'Course Name',
      name:  (
        <>
          Course Name {sorting === "nameasc"?
        <FaArrowDownAZ size={24}  onClick={() => sortingDateandTitle("namedsc")} className='Document-createDateasc'/>
      :
          <FaArrowUpAZ  size={24}  onClick={() => sortingDateandTitle("nameasc")} className='Document-createDateasc'/>
        }
        </>
      ),
      Call: function(rowData){
        return(
          <>
           <ToolTipCmp text="Edit Course" widths={"100%"}>
         <Button
          onClick={() => handleItemClick1(rowData)}
          className="myButton"
         >
         {rowData?.course_Name}
      </Button>
      </ToolTipCmp>
          </>
        )
      }
     
  },
   
    {
      datan: 'status',
      name: 'Course Status',
    },
    {
      datan: 'action',
      name: 'Action',
      Call: function (rowData) {
        return <>

           <CustomDropdownButton
            menuItems={[
              
              // { label: 'View Module', onClick: () => handleViewModuleClick(rowData) },
              // { label: 'Edit Course',  onClick: () => handleItemClick1(rowData) },
              { label: 'Delete Course', onClick: () => openModel1(rowData._id)},
             { label: 'View Course History', onClick: () => navigate(`/historyCourse`,{state:{histor:rowData}})},
            //  { label: 'Generate Course', onClick: () => downloadCourse(rowData)},
             { label: 'Generate course', onClick: () => downloadCoursedoc(rowData)}
            ]}
          />
        </>;
      }
    }
  ];
  const [mod3, setMod3]=useState(false);

  const removeIndex = (row) => {
    console.log("rowrow", row);
    const excluedename = 'Class Start,Lunch,Break,Day End'.split(',');
    if (excluedename.includes(row.fileTitle)) {
      return;
    }else if(!row.moduleId){
      return;
    }{
      openModel3(row);
    }
  
  };


  
  const handleItemClick1 = (row) => {
    console.log(row,"kjj");
    setTitle("Edit Course");
    setAddCourse(row);
    setRows([...row?.module,{ moduleId: '', dup: false, threeHole: false, stpl: false,s_mnal: false,t_pk:false,t_sld:false,sort:'',quantity:'',duration:'00',tabsText:'',startTime:'',type:"",desc:"" }]);
    TimeTableGenerate("true",row?._id);
    openModel();
  
  };
  const [set, setModel] = useState(false);
  const openModel = () => { setModel(true) }
  const closeModel = () => { setModel(false);
    setError('');
    setAddCourse({
      course_Name:'',desc:'',status:"",active:true,notes:'',tutorBox:true,studentManual:true,
     tabs:true,index:false,tutorSlides:true,packagingList:true,courseDocumentChanges:true,exam:true
    });
    setRows([{}]);setTitle('')}

    
  
    
  function body() {
    return <>
        
      <Container>
        <div style={{border:'1px solid lightgrey',marginBottom:'6px'}}>
      <Row className="g-1" style={{paddingLeft:'9px',paddingRight:'9px',paddingTop:"8px"}}>
      <Col md={6} sm={12} lg={3}>
       <FormButton onClick={() => downloadCourse()} style={{ marginBottom: '10px' }} width='85%'>Course Docs</FormButton>
       {/* <FormButton width='85%'>Module By Type</FomButton> */}
        </Col>
        <Col md={6} sm={12} lg={3}>
        <FormButton onClick={smPack} width='85%' style={{ marginBottom: '10px' }}>SM Docs</FormButton>
        {/* <FormButton width='85%'>Module By Type</FormButton> */}
        </Col>
        <Col md={6} sm={12} lg={3}>
        <FormButton onClick={tpPack} width='85%' style={{ marginBottom: '10px' }}>TP Docs</FormButton>
        {/* <FormButton width='85%'>Module By Type</FormButton> */}
        </Col>
     
        <Col md={6} sm={12} lg={3}>
       <FormButton onClick={tsPack} style={{ marginBottom: '10px'}} width='85%'>TS Docs</FormButton>
       {/* <FormButton width='85%'>Module By Type</FormButton> */}
        </Col>
      </Row>
      <Row className="g-1" style={{paddingLeft:'9px',paddingRight:'9px'}}>
      <Col md={6} sm={12} lg={3}>
       <FormButton onClick={tmPack} style={{ marginBottom: '10px'}} width='85%'>Course Doc By List</FormButton>
       {/* <FormButton width='85%'>Module By Type</FormButton> */}
        </Col>
      <Col md={6} sm={12} lg={3}>
       <FormButton onClick={() => ModuleListGenerate()} style={{ marginBottom: '10px' }} width='85%'>Module List</FormButton>
       {/* <FormButton width='85%'>Module By Type</FomButton> */}
        </Col>
      </Row>
      </div>


        <Row className="g-1" style={{border:'1px solid lightgrey',padding:'8px'}}>
          <Col md={6} sm={12} lg={5}>
            <InputField
              label="Name"
              required
              name='course_Name'
              placeholder='Enter Course Name'
              value={addCourse?.course_Name}
              onChange={handleChange}
            />
            {error === "Course Name is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
    <SelectComponent label={'Status'} 
     required={true}
    value={addCourse?.status}
    options={[
    { label: "Select Course Status", value: "" },
    { label: "Need to update", value: "Need to update" },
    { label: "No longer service", value: "No longer service" },
    { label: "Released", value: "Released" },
  ]
}

onChange={(value)=>handleSelect(value)}/> 
{error === "Status is required" ? <p style={{color:'red',marginLeft:'15px'}}>{error}</p>:null}
<InputField
              label="Description"
              placeholder='Enter Description'
              name='desc'
              value={addCourse?.desc}
              onChange={handleChange}
            />
            <InputField
              label="Notes"
              name='notes'
              placeholder='Enter Notes'
              value={addCourse?.notes}
              onChange={handleChange}
            />

  
          </Col>
          <Col md={6} sm={12} lg={3} className="d-flex flex-column">
          <CheckBoxComponent type="checkbox" label="Active" checked={addCourse?.active}  onChange={(event) => {setAddCourse({...addCourse,active:event.target.checked})}} />

        <FormButton onClick={listPack} style={{marginBottom:'5px',marginTop:'5px'}}>Generate Package</FormButton> 
      <FormButton style={{  marginBottom: '5px' }} onClick={()=>TimeTableGenerate("false")}>Time Table</FormButton>
    </Col>
          <Col md={6} sm={12} lg={4} style={{border:'1px solid lightgrey'}}>
            <div style={{display:'flex',justifyContent:'center',marginTop:"10px"}}>
            <FormButton onClick={PublishCourse}>Publish Course</FormButton>
            </div>
            <ModalComponent
          title={headerdate}
          status={modaldate}
          onHide={closeModeldate}
          footer={footerdate}
          body={bodydate}
          animation={true}
          centered={"centered"}
          // size={'xl'}
        />
            <Row style={{marginTop:'10px'}}>
              <Col>
              <CheckBoxComponent type="checkbox" label="SM"  checked={collection?.sm}  onChange={(event) => {setCollection({...collection,sm:event.target.checked})}}/>
             <CheckBoxComponent type="checkbox" label="Index"  checked={collection?.index}  onChange={(event) => {setCollection({...collection,index:event.target.checked})}}/>
             <CheckBoxComponent type="checkbox" label="TS"  checked={collection?.t_sld}  onChange={(event) => {setCollection({...collection,t_sld:event.target.checked})}}/>
             <CheckBoxComponent type="checkbox" label="Packing List"  checked={collection?.packagingList}  onChange={(event) =>{setCollection({...collection,packagingList:event.target.checked})}}/>
              </Col>
              <Col>
              <CheckBoxComponent type="checkbox" label="TP"  checked={collection?.t_pk}  onChange={(event) => {setCollection({...collection,t_pk:event.target.checked})}}/>
            <CheckBoxComponent type="checkbox" label="Tabs"  checked={collection?.tabs}  onChange={(event) => {setCollection({...collection,tabs:event.target.checked})}}/>
            <CheckBoxComponent type="checkbox" label="Exam"  checked={collection?.exam}  onChange={(event) => {setCollection({...collection,exam:event.target.checked})}}/>
            <CheckBoxComponent type="checkbox" label="Course Document Changes"  checked={collection?.courseDocumentChanges}  onChange={(event) => {openModeldate(event.target.checked);setCollection({...collection,courseDocumentChanges:event.target.checked});}}/>
            {error === "Select Start date and End date" ? <p style={{color:'red'}}>{error}</p>:null}
              </Col>
            
            </Row>
            
            
          
           
          </Col>
        </Row>

        <Row className='g-1' style={{padding:'8px'}}>
         <Col md={12} lg={12}>
         <div >
         <DragDropContext onDragEnd={handleDragEnd} >
          <Table>
      <thead style={{width:'100%',overflow:'auto'}}>
        <tr>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Content</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>qty</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Min.</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Tab</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Started at</th>
          <th></th>
          {/* <th style={{fontSize:'14px',fontWeight:'normal'}}>Dup</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>3H</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>Stpl</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>5-Mnl</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>T-Pk</th>
          <th style={{fontSize:'14px',fontWeight:'normal'}}>T-Sld</th> */}
          {/* <th style={{fontSize:'14px',fontWeight:'normal'}}>Sort</th> */}
        </tr>
      </thead>
      <Droppable droppableId="droppable">
      {(provided) => (
        <tbody {...provided.droppableProps} ref={provided.innerRef}>
          {rows.map((row, index) => (
            <Draggable key={index} draggableId={String(index)} index={index} isDragDisabled={index === rows.length - 1}>
              {(provided) => (

<React.Fragment>
{index === 0 && error === "Select Class Start" && (
  <tr>
    <td colSpan="5" style={{ color: 'red' }}>{error}</td>
  </tr>
)}
                <tr ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}>
      
            <td>
              
             <Select
      //  options={option}
      options={option.filter(
        options => !rows.slice(0, index).some(
          row => row.moduleId === options.value && !excludedIds.includes(options.value)
        )
      )}
       defaultValue={row?.moduleId}
      // defaultValue={row?.moduleId}
      value={option.find((opt) => opt.value === row.moduleId)}
      onChange={(selectedOption) =>
        handleSelectChanges(index, selectedOption, "moduleId", "fileTitle")
      }
    />
          </td>
          <td>
          <Select
           value={quqntity.find(option => option.value === row.quantity)}
            onChange={selectedOption => handleSelectChanges(index, selectedOption, "quantity")}
            options={quqntity} 
            isDisabled={["Class Start", "Lunch", "Break", "Class Wrap up", "Day End"].includes(row.fileTitle)}/>
          </td>
          <td>
          <InputGroup className="mb-3" style={{ width: '40px', height: '1%' }}>
  <Form.Control
    aria-label="Example text with button addon"
    aria-describedby="basic-addon1"
    style={{ padding: '6px 1px',textAlign:'center' }}
    type='text'
    inputmode="numeric"
    name='duration'
    disabled={row.fileTitle === "Class Start"}
    value={row?.duration}
    onChange={(e) => {
      const numericValue = e.target.value.replace(/[^0-9]/g, '');
      handleInputChange({ ...e, target: { ...e.target, value: numericValue } }, index, "duration");
    }}
    onKeyPress={(e) => {
      if (e.key !== 'Enter' && e.key !== 'Backspace' && e.key !== 'Delete' && isNaN(Number(e.key))) {
        e.preventDefault();
      }}}
  />
    
</InputGroup>
            {/* <input type='text' style={{width:'50px',height:'25px'}} value={row?.duration} onChange={(e) => handleInputChange(e,index,"duration")}/> */}
          </td>
          <td>
          <InputGroup className="mb-3" style={{ width: '70px', height: '1%' }}>
  <Form.Control
    aria-label="Example text with button addon"
    aria-describedby="basic-addon1"
    style={{ padding: '6px 1px',textAlign:'center' }}
    type='text'
    name='tabText'
    value={row?.tabsText || ''}
    disabled
  />
</InputGroup>
          {/* <input type='text' style={{width:'90px',height:'25px'}} value={row?.tabsText} onChange={(e) => handleInputChange(e,index,"tabsText")}/> */}
          </td>
          <td>
          <InputGroup className="mb-3" style={{ width: '105px', height: '1%' }}>
  <Form.Control
    aria-label="Example text with button addon"
    aria-describedby="basic-addon1"
    style={{ padding: '6px 1px',textAlign:'center' }}
    type='time'
    value={row?.startTime}
    // disabled={row?.fileTitle != "Start"}
    onChange={(e) => handleInputChange(e,index,"startTime")}
  />
</InputGroup>
         
          </td>
          <td>
          <CloseOutlined onClick={()=>removeIndex(row)}/>
          </td>
          {/* <td >
          <Form.Check aria-label="option 1" checked={row?.dup}   onChange={(e) => handleInputChange(e,index,"dup")} />
          </td>
          <td >
          <Form.Check aria-label="option 1" checked={row?.threeHole}   onChange={(e) => handleInputChange(e,index,"threeHole")} />
          </td>
          <td >
          <Form.Check aria-label="option 1" checked={row?.stpl}   onChange={(e) => handleInputChange(e,index,"stpl")} />
          </td>
          <td >
          <Form.Check aria-label="option 1" checked={row?.s_mnal}   onChange={(e) => handleInputChange(e,index,"s_mnal")} />
          </td>
          <td >
          <Form.Check aria-label="option 1" checked={row?.t_pk}   onChange={(e) => handleInputChange(e,index,"t_pk")} />
          </td>
          <td >
          <Form.Check aria-label="option 1" checked={row?.t_sld}   onChange={(e) => handleInputChange(e,index,"t_sld")} />
          </td> */}
        </tr>
        </React.Fragment>
             )}
             </Draggable>
           ))}
           {provided.placeholder}
         </tbody>
       )}
     </Droppable>
   </Table>
 </DragDropContext>
        </div>

          </Col>
        </Row>
      </Container>
    </>
  }
  function header() {
    return(
    <Container>
    <Row  >
      <Col md={12} lg={8}>
          <h3>{title ? title+": "+addCourse?.course_Name:'Add Course'}</h3>
      </Col>
      <Col md={12} lg={4}>
        <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>
          {/* <Package tableLength={rows} datas={addCourse? addCourse:''}/> */}
          {/* <TimeTablePackage tableLength={rows} datas={addCourse? addCourse:''}/> */}
          
          {/* {title ?<FormButton onClick={smPack}>SM Docs</FormButton> : null}
          {title ? <FormButton onClick={tpPack}>TP Docs</FormButton> : null }
          {title ? <FormButton onClick={tmPack}>Time Table</FormButton> : null} */}
        </div>
       </Col>
      </Row>
      </Container>
    )
    
  }
  function footer() {
    return <>
      {/* <Stack direction="horizontal" gap={2}> */}
      <Row className="g-1" width={"100%"}>
        <Col md={12} lg={6}>
        <FormButton onClick={closeModel}>Close</FormButton>
        </Col>
        <Col md={12} lg={6}>
        <FormButton onClick={addCorses}>Save</FormButton>
        </Col>
        </Row>
      {/* </Stack> */}
    </>
  }


  const handlePageChange = (newPage) => {
    getCourse(newPage)
  };

 //--------------model1-----------//

 const [modal1,setModal1]=useState(false);
 const [modal2,setModal2]=useState(false);
const openModel1 = (id) =>{
  // console.log("hhhhh",id);
  setModal1(true)
  setIds(id);
}
const closeModel1 = () =>{

  setModal1(false)
}

const openModel2 = (data) =>{

  setModal2(true)
   setLink(data);
}
const closeModel2 = () =>{
  setModal2(false)
}


function body1(){
  return<>
  <Container>
      Are you sure! Do you want Delete?
  </Container>
  </>
}
function footer1 (){
  return<>
 <Stack direction="horizontal" gap={2}>
      <FormButton onClick={closeModel1}>Close</FormButton>
      <FormButton onClick={deleteCourses}>Delete</FormButton>
  </Stack>
  </>
}
function header1 (){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h3>Delete Course</h3>
  </Stack>
  </>
}


function body2(){
  return<>
  <Container>
      Are you sure! Do you want Download?
      <br></br>
      <a href={link} download={link}>Click here to download</a>
  </Container>
  </>
}
function footer2 (){
  return<>
 <Stack direction="horizontal" gap={2}>
      <FormButton onClick={closeModel2}>Close</FormButton>
  </Stack>
  </>
}
function header2 (){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h3>Download Course</h3>
  </Stack>
  </>
}

//------------------ remove module --------------//
const [removeId, setRemoveId]=useState('');
const openModel3 = (data) =>{
  setMod3(true);
  setRemoveId(data);

}
const closeModel3 = () =>{

  setMod3(false)
}
function header3(){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h3>{removeId?.fileTitle}</h3>
  </Stack>
  </>
}
function footer3 (){
  return<>
  <Row className="g-1" width={"100%"}>
        <Col md={12} lg={6}>
        <FormButton onClick={closeModel3}>Close</FormButton>
        </Col>
        <Col md={12} lg={6}>
        <FormButton onClick={()=>{setRows((prevRows) => prevRows.filter((r) => r.moduleId !== removeId?.moduleId));setMod3(false);}}>Remove</FormButton>
        </Col>
        </Row>
  </>
}

  

function body3(){
  return<>
   <Container>
      Are you sure! Do you want remove from the list?
  </Container>
  </>
}



//------------------document Change Date --------------//

const openModeldate = (data) =>{
  console.log("hhhhhhhhhhhhhhhhhffffffffffff",data);
   if(data == true){
    setModaldate(true)
  }else{
    setDocDate('')
  }

}
const closeModeldate = () =>{

  setModaldate(false)
}
function footerdate (){
  return<>
  <Row className="g-1" width={"100%"}>
        <Col md={12} lg={6}>
        <FormButton onClick={closeModeldate}>Close</FormButton>
        </Col>
        <Col md={12} lg={6}>
        <FormButton onClick={savedDate}>Save</FormButton>
        </Col>
        </Row>
  </>
}

  

function bodydate(){
  return<>
  <Container>
      <RangeDatePicker   filterDate={onDate}/>
  </Container>
  </>
}

function headerdate (){
  return<>
 <Stack direction="horizontal" gap={2}>
     <h3>Choose Date</h3>
  </Stack>
  </>
}


const buttonList=()=>{
 return(
   <Container style={{marginBottom:'5px'}}>
      <Row >
      <Col md={6} sm={12}  lg={3} >
      <InputField
        width='100%'
        icon={<SearchOutlined />}
        // onChange={search}
      />
      <button>Document List</button>
      <button>SM Docs</button>
      <button>TP Docs</button>
      <button>TS Docs</button>
      <button>Module By Type</button>
        </Col>
        <Col md={6} sm={12}  lg={3} >
          <FormButton className="mb-2">Document List</FormButton>
          <FormButton className="mb-2">Student Manual List</FormButton>
        </Col>
        <Col md={6} sm={12}  lg={3} >
          <FormButton className="mb-2">Tutor Pack List</FormButton>
          <FormButton className="mb-2">Exam Pack List</FormButton>
        </Col>
        <Col md={6} sm={12}  lg={3} >
          
          <FormButton
            variant="primary"
            onClick={() => openModel()}
          >
            Add New Course
          </FormButton>
        </Col>
      </Row>
    </Container>
 )
}


  return (
    <div >
       {load === true && (
      <div className="spinner-container">
      <div className="text-center">
      <Spinner
        animation="border"
        role="status"
        style={{
          borderWidth: '2px',
          width: '60px',
          height: '60px',
        }}
      >
        <span className="visually-hidden">Loading...</span>
      </Spinner>
      <div>Loading...</div>
    </div>
    </div>
    )}
     
          <SuccessMessage message={message?.successMessage || ''}/>
    
          <ErrorMessage message={message?.errorMessage || ''} />
        
    
     
     
      <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
     
        <ModalComponent
          title={header}
          status={set}
          onHide={closeModel}
          footer={footer}
          body={body}
          animation={true}
          size={'xl'}
        />
         <ModalComponent
        title={header1}
        status={modal1}
        onHide={closeModel1}
        footer= {footer1}
        body= {body1}
        animation={true}
       />
         <ModalComponent
          title={header2}
          status={modal2}
          onHide={closeModel2}
          footer={footer2}
          body={body2}
          animation={true}
          // size={'xl'}
        />
         <ModalComponent
          title={header3}
          status={mod3}
          onHide={closeModel3}
          footer={footer3}
          body={body3}
          animation={true}
          // size={'xl'}
        />
         <div style={{ width: '100%'}}> 
 <Row >
      <Col xl={4} lg={4} md={6} sm={12} style={{marginBottom:"2px",}}>
      <InputGroup style={{ width: '100%',boxShadow:'none'}} >
      <InputGroup.Text>
        {/* <Search /> */}
        <SearchOutlined />
      </InputGroup.Text>
      <FormControl
       style={{boxShadow:'none'}}
        placeholder="Enter Course Name, Course Status"
        onChange={search}
      />
    </InputGroup>
  </Col>
  <Col xl={5} lg={5} md={2} sm={12} >
      </Col>
      <Col xl={3} lg={3} md={4} sm={12} >
  <FormButton
  width='100%'
            variant="primary"
            onClick={() => openModel()}
          >
            Add New Course
          </FormButton>
  </Col>
  
  </Row>
  </div>
 
       
      </div>
      
     
       
    
      <div >
        <TableComponent
          columns={columns}
          data={course}
        />
      </div>
   
      <CustomPagination currentPage={currentPage} totalPages={totalPage} onPageChange={handlePageChange} />

    </div>

  );
};

export default Courese;
