import React, { useEffect, useState } from 'react';
import { TableComponent, FormButton, ModalComponent, CustomDropdownButton, InputField } from '../../component/index';
import { Col, Container, Row, Spinner, Stack } from 'react-bootstrap';
import CustomPagination from '../../functions/pagination';
import { addRole, getRole } from '../../API/roleapi';
import SuccessMessage from '../../component/messagecomponent/successMessage';
import ErrorMessage from '../../component/messagecomponent/errorMessage';

const Role = () => {
  const [error, setError] = useState('');
  const [currentPage,setCurrentPage]=useState('');
  const [load, setLoad] = useState(false);
  const [role, setRole] = useState('');
  const [status, setStatus] = useState('');
  const [allRole, setAllRole] = useState([]);
  const [title, setTitle] = useState('');
  const [message, setMessage] = useState({
    successMessage: '',
    errorMessage: '',
  });

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    setLoad(true);
    try {
      const response = await getRole();
      setAllRole(response?.data?.data || []);
    } catch (error) {
      console.error('Error while fetching roles:', error);
    } finally {
      setLoad(false);
    }
  };

  const deleteRole=async(id)=>{
    console.log(id,"hhh");
    // if(id){
    //   setLoad(true);
    //   const result = await deleteRole(id);
    //   setLoad(false);
    //   console.log(result,";jih");
    //   if(result?.status === 201){
    //         setMessage({
    //           message,
    //           successMessage:result?.data?.message
    //         })
    //         getRole(currentPage);
    //   }else{
    //         setMessage({
    //           message,
    //           errorMessage:"Somthing went wrong"
    //         })
    //   }
    // }else{
    //   console.log("id required");
    // }
  }
  



  const columns = [
    {
      datan: 'role',
      name: 'Role',
    },
    {
      datan: 'roleActive',
      name: 'Status',
      Call: (rowData) => {
        return rowData.roleActive ? 'Active' : 'Inactive';
      },
    },
    {
      datan: 'action',
      name: 'Action',
      Call: (rowData) => {
        return (
          <CustomDropdownButton
            menuItems={[
              { label: 'Edit Role', onClick: () => handleEditRole(rowData) },
              { label: 'Delete Course', onClick: () => deleteRole(rowData._id)},
            ]}
          />
        );
      },
    },
  ];

  const handleEditRole = (row) => {
    setTitle('Edit Role');
    setRole(row.role);
    setStatus(row.roleActive ? 'True' : 'false');
    openModel();
  };

  const handleRadioChange = (e) => {
    setStatus(e.target.value);
  };

  const handleSelectChange = (e) => {
    setRole(e.target.value);
  };

  const [isModelOpen, setModel] = useState(false);
  const openModel = () => setModel(true);
  const closeModel =() => {setModel(false);setRole('');setStatus('')};

  const fetchApi = async () => {
    const data = {
      role: role,
      roleActive: status === 'True',
    };

    try {
      setLoad(true);
      const response = await addRole(data);
      console.log('response', response);

      if (response.status === 201) {
        setMessage({
          ...message,
          successMessage: 'Role Added Successfully',
        });
        closeModel();
      }
    } catch (error) {
      console.error('Error while adding role:', error);
      setMessage({
        ...message,
        errorMessage: 'Failed to add role',
      });
    } finally {
      setLoad(false);
    }
  };

  function body() {
    return (
      <Container>
        <Row className="g-1" style={{ justifyContent: 'center', alignItems: 'center' }}>
          <Col md={12} lg={6}>
            <InputField
              label="Role"
              required
              value={role}
              onChange={handleSelectChange}
            />
            <div style={{ marginLeft: '10px' }}>
              <p>Status</p>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio1"
                  checked={status === 'True'}
                  onChange={handleRadioChange}
                  value="True"
                />
                <label className="form-check-label" htmlFor="inlineRadio1">
                  Active
                </label>
              </div>
              <div className="form-check form-check-inline">
                <input
                  className="form-check-input"
                  type="radio"
                  name="inlineRadioOptions"
                  id="inlineRadio2"
                  checked={status === 'false'}
                  onChange={handleRadioChange}
                  value="false"
                />
                <label className="form-check-label" htmlFor="inlineRadio2">
                  Inactive
                </label>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    );
  }

  function footer() {
    return (
      <Stack direction="horizontal" gap={2}>
        <FormButton onClick={closeModel}>Cancel</FormButton>
        <FormButton onClick={fetchApi}>Save</FormButton>
      </Stack>
    );
  }
function titles(){
  <Stack direction="horizontal" >
      <h3>{title ? title:"Edit Role"}</h3>
  </Stack>
}
  const handlePageChange = (newPage) => {
    console.log('Page changed to:', newPage);
  };

  return (
    <div>
      {load && (
        <div className="spinner-container">
          <div className="text-center">
            <Spinner
              animation="border"
              role="status"
              style={{
                borderWidth: '2px',
                width: '60px',
                height: '60px',
              }}
            >
              <span className="visually-hidden">Loading...</span>
            </Spinner>
            <div>Loading...</div>
          </div>
        </div>
      )}

      <SuccessMessage message={message.successMessage} />
      <ErrorMessage message={message.errorMessage} />

      <div style={{ width: '100%', display: 'flex', justifyContent: 'end', marginBottom: '10px' }}>
        <ModalComponent
          title={titles}
          status={isModelOpen}
          onHide={closeModel}
          footer={footer}
          body={body}
          animation={true}
        />
        {error === 'Role is required' ? <p style={{ color: 'red', marginLeft: '15px' }}>{error}</p> : null}
        <FormButton variant="primary" onClick={openModel}>
          Add Role
        </FormButton>
      </div>

      <div>
        <TableComponent columns={columns} data={allRole} />
      </div>

      <CustomPagination currentPage={1} totalPages={1} onPageChange={handlePageChange} />
    </div>
  );
};

export default Role;
