import React,{ useEffect, useState } from 'react';
import { MenuUnfoldOutlined,UserOutlined,MenuFoldOutlined } from '@ant-design/icons';
import '../assets/Header.css';
import { Button, Container, DropdownButton, Stack } from 'react-bootstrap';
import { LogoutOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { authLogin } from '../state/action/authAction';
import { useDispatch } from 'react-redux';
import { FormButton, ModalComponent } from '../component';


const Header = ({onclik,drawer}) => {
  // const [profile,setProfile]=useState();
  const navigate= useNavigate(); 
  // function profiles(){
  //   setProfile(true);
  // }
const dispatch = useDispatch();
  useEffect(() => {
    const token = localStorage.getItem("accessToken");
    if (!token) {
      handleLogout();
    }
    
  }, []);
  

   const handleLogout = () => {
    dispatch(authLogin(null, true));
    localStorage.removeItem("accessToken");
    localStorage.removeItem("userType");
    navigate('/');
  };

  const [modal2,setModal2]=useState(false);

  const openModel2 = () =>{

    setModal2(true)
  }
  const closeModel2 = () =>{
    setModal2(false)
  }
  function body2(){
    return<>
    <Container>
        Are you sure, you want to Logout?
    </Container>
    </>
  }
  function footer2 (){
    return<>
   <Stack direction="horizontal" gap={2}>
        <FormButton onClick={closeModel2}>No</FormButton>
        <FormButton onClick={handleLogout}>Yes</FormButton>
    </Stack>
    </>
  }
  function header2 (){
    return<>
   <Stack direction="horizontal" gap={2}>
       <h3>Logout</h3>
    </Stack>
    </>
  }


  return (
    <div style={{position:'fixed',backgroundColor:'#AA1539',width:'100%',zIndex:'999'}}>
           <ModalComponent
          title={header2}
          status={modal2}
          onHide={closeModel2}
          footer={footer2}
          body={body2}
          animation={true}
          // size={'xl'}
        />
    <div className={drawer === true ? 'mainheader':'main1header'}>
  <div> 
    {
      drawer === true?
      <MenuFoldOutlined onClick={onclik} style={{ fontSize: '30px', color: 'white' }}/>
      :
      <MenuUnfoldOutlined onClick={onclik} style={{ fontSize: '30px', color: 'white' }} />
    }
    
  </div>
  <div style={{marginRight:'8%'}}>
    {/* <UserOutlined onClick={profiles} style={{ fontSize: '30px', color: 'white' }}/> */}
    <DropdownButton
      id="dropdown-basic-button"
      title={<UserOutlined className='user' />}
      className="custom-dropdown-button dropdown"
      
    >
      <div style={{marginRight:'10px',marginLeft:'10px'}}>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
        <p style={{fontWeight:'bold'}}>{localStorage.getItem("userName")}</p>
      </div>
      <div style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
      <FormButton
      onClick={openModel2}
      width='120px'
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        textAlign: 'center',
      }}
    >
      Logout <LogoutOutlined style={{ marginLeft: '5px' }} />
    </FormButton>
      </div>
      
      </div>
    </DropdownButton>
  </div>


  </div>
  </div>
  );
};

export default Header;