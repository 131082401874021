import { takeLatest, put, call } from 'redux-saga/effects';
// import axios from 'axios';
import { login } from '../../API/authapi';

function* authSaga(action) {
  try {
    const { user,logout } = action.payload;
    if (logout) {
      // Dispatch the action to reset the Redux state
      yield put({ type: 'RESET_AUTH_STATE' });

      // Additional logout logic, e.g., clearing local storage
      localStorage.removeItem('accessToken');
      localStorage.removeItem('userType');

      // Exit the saga early if it's a logout action
      return;
    }
    yield put({ type: 'AUTH_LOGIN_REQUEST' });
    
    // Use the call effect to make the API request and pass the user object as the second argument
    const response = yield call(login, user);
    // console.log("hh",response?.message);
    if(response?.message === "Login successfully"){
        // console.log("hh",response);
        localStorage.setItem("accessToken",response?.accessToken);
        localStorage.setItem("userType",response?.data?.role)
        localStorage.setItem("userName",response?.data?.name)
        yield put({ type: 'AUTH_LOGIN_SUCCESS', payload: response });

    }else{
      yield put({ type: 'AUTH_LOGIN_FAILURE', payload:response?.response?.data?.error });
    }

   
  } catch (error) {
    console.log(error, "ehhhhhhhhhhhhhhhrror");
    yield put({ type: 'AUTH_LOGIN_FAILURE', payload:error });
  }
}

function* authLoginWatcher() {
  yield takeLatest('AUTH_LOGIN', authSaga);
}

export default authLoginWatcher;
