const initialState = {
    loginResponse: null,
    loginSuccess: null, 
    loginLoading: false,
    loginError: null,
    user: null,
  };
  
  const authReducer = (state = initialState, action) => {
    switch (action.type) {
      case 'AUTH_LOGIN_REQUEST':
        return {
          ...state,
          loginLoading: true,
          loginSucess:null,
          loginError: null,
        };
      case 'AUTH_LOGIN_SUCCESS':
        return {
          ...state,
          loginResponse: action.payload,
          loginSucess:"Sucess",
          loginLoading: false,
          loginError: null,
        };
      case 'AUTH_LOGIN_FAILURE':
        return {
          ...state,
          loginLoading: false,
          loginError: action.payload,
        };
      case 'RESET_AUTH_STATE':
        return initialState;
      default:
        return state;
    }
  };
  
  export default authReducer;
  