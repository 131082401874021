import React from 'react';
import PropTypes from 'prop-types';

const ToolTipCmp = ({ text, children,widths }) => {
  const [showTooltip, setShowTooltip] = React.useState(false);

  const handleMouseEnter = () => {
    setShowTooltip(true);
  };

  const handleMouseLeave = () => {
    setShowTooltip(false);
  };

  return (
    <div
      style={{ position: 'relative', display: 'inline-block',width:widths }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      {children}
      {showTooltip && (
        <div
        style={{
            position: 'absolute',
            backgroundColor: 'rgba(0, 0, 0, 0.8)',
            color: 'white',
            padding: '5px',
            borderRadius: '10px', // Adjust the border radius for curved appearance
            zIndex: '999',
            top: '50%', // Center vertically
            left: '100%', // Position to the right
            transform: 'translateY(-50%)',
            maxWidth: '150px',
            wordWrap: 'break-word',
            whiteSpace: 'nowrap',
          }}
      >
          {text}
        </div>
      )}
    </div>
  );
};

ToolTipCmp.propTypes = {
  text: PropTypes.string.isRequired,
  children: PropTypes.element.isRequired,
};

export default ToolTipCmp;