import { Card, InputGroup, Spinner } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import  '../../assets/Login.css';
import { useNavigate } from 'react-router-dom';
import { useDispatch,useSelector} from 'react-redux';
import { authLogin } from '../../state/action/authAction';
import { useState,useEffect } from 'react';
import ErrorMessage from '../../component/messagecomponent/errorMessage';
import { ExclamationCircleOutlined, EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import loginImg from "../../assets/images/logo-2 (1).png"


function Login() {
  const navigate=useNavigate()
  const dispatch = useDispatch();
  const [error, setError] = useState(false)
  const [isLoading,setIsLoading]=useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [candidate, setCandidate] = useState({
    email: '', // Make sure to initialize all required fields
    password:''
  });
  // const [accessToken, setAccessToken] = useState('');
  const loginState = useSelector(state => state.authReducer);
  const [credError,setCredError]=useState('');
 const a= useSelector((state)=>state?.authReducer.loginError);
 console.log(a,"kkk");


  
  const handleChange = (e) => {
    setCredError('');
    console.log(e.target.value, "jkjkjj");
    try {
      setCandidate({
        ...candidate,
        [e.target.name]: e.target.value,
      });
    } catch (error) {
      console.log(error);
    }
  }


  const handleLogin = async (event) => {
 
    event.preventDefault();
       if (candidate?.email === "" ) {
      setCredError("Enter Email");
    }else if(candidate?.password === ""){
      setCredError("Enter Password")
    }else{
   
      setCredError('');
    const user = {
      email: candidate.email,
      password: candidate.password
    };

    try {
      setIsLoading(true);
       dispatch(authLogin(user));
       setIsLoading(false);
    } catch (error) {
      console.error("API Request Error:", error);
    }
  }
  };

  // useEffect(() => {
  //   setCredError(a);
  //   if (loginState.loginSuccess === true) {
  //     window.location.reload();

  //     navigate('/document');
  //   } else if (loginState.loginError != null) {
  
  //   }
  // }, [loginState.loginSuccess,loginState.loginError,navigate,a]);


  useEffect(() => {
    console.log(loginState);
    if (loginState.loginSucess === "Sucess") {
      // setErr(false);
      setCredError("");
      // window.location.reload();
      setIsLoading(false);
      navigate("/document");
    } else if (loginState.loginError === null) {
      // setErr(false);
      setCredError('');
      setIsLoading(false);
    } else if (loginState.loginError != null) {
 
      const errorMessage = loginState.loginError;
      // setErr(true);
        setCredError(errorMessage);
      setIsLoading(false);
    } 
    else{
      // setErr(false);
      setCredError("");
    
    }
  }, [loginState]);




  return (
    <div className='Bkgcolor'  style={{display:"flex",justifyContent:"center",alignItems:"center"}}>
   {/* <ErrorMessage message={credError || ''}/> */}
    <Card >
    <Card.Body>
    {credError ? <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', background: 'lightgrey', padding: '10px' }}>
    <ExclamationCircleOutlined style={{ color: 'red', marginRight: '10px',fontSize:'23px' }} />
    <p style={{ color: 'red', margin: 0,fontWeight:'500' }}>{credError}</p>
  </div>:null}
    <Form >
    <Form.Group className="mb-3" controlId="formBasicImage" style={{display:'flex',flexDirection:'row',justifyContent:'center'}}>
      <img  src={loginImg}  width={"40%"}/>
      </Form.Group>
      <Form.Group className="mb-3" controlId="formBasicEmail">
        <Form.Label style={{fontWeight:'500'}}>Email address</Form.Label>
        <Form.Control
          type="email"
          name="email" 
          value={candidate.email}
          onChange={handleChange} 
          placeholder="Enter email"
        />
      </Form.Group>

      <Form.Group className="mb-3" controlId="formBasicPassword">
  <Form.Label style={{ fontWeight: '500' }}>Password</Form.Label>
  <InputGroup>
    <Form.Control
      type={showPassword ? 'text' : 'password'}
      name="password"
      value={candidate.password}
      onChange={handleChange}
      placeholder="Password"
    />
    <InputGroup.Text id="inputGroup-sizing-sm">
    { showPassword ? (
        <EyeOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => setShowPassword(!showPassword)}
        />
      ) : (
        <EyeInvisibleOutlined
          style={{ cursor: 'pointer' }}
          onClick={() => setShowPassword(!showPassword)}
        />
      )
}
    </InputGroup.Text>

  </InputGroup>
</Form.Group>


      
      <div style={{display:"flex", flexDirection:"center",justifyContent:"center",alignItems:"center"}}>
      <Button style={{backgroundColor:'#3F113C',border:'1px solid #3F113C',paddingLeft:'10%',paddingRight:'10%',borderRadius:'20px'}} type="submit" onClick={handleLogin}> 
      {isLoading ?
      <Spinner animation="border" role="status">
      <span className="visually-hidden">Loading...</span>
    </Spinner>
    :
 'Login'
      }

</Button>
      </div>
      <div  style={{display:'flex',justifyContent:"flex-end",alignItems:"center"}} className='account'>
      {/* <Button variant="link" onClick={()=>navigate('/')}>Dont have account?</Button> */}
      </div>

    </Form>

    </Card.Body>
  </Card>
   </div>


  );
}

export default Login;






// import React from 'react';
// import { FloatingLabel, Form } from 'react-bootstrap';
// import '../../assets/Login1.css';
// import { UserOutlined, MailOutlined, LockOutlined } from '@ant-design/icons';
// import { useState } from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { authLogin } from '../../state/action/authAction';
// import { useEffect } from 'react';
// import { useNavigate } from 'react-router-dom';
// import ErrorMessage from '../../component/messagecomponent/errorMessage';


// function Login() {

//   const navigate = useNavigate()
//   const dispatch = useDispatch();
//   const [email, setEmail] = useState('');
//   const [password, setPassword] = useState('');
//   const [loginMessage, setLoginMessage] = useState('');
//   const loginState = useSelector(state => state.authReducer);
//   const [error, setError] = useState(false)
//   const a=useSelector((state)=>state?.authReducer.loginError);
//   console.log("kkkk",a);
//   const handleLoginClick = async (e) => {
//     e.preventDefault();
//     if (email === "" ) {
//       setError("Enter Email");
//     }else if(password === ""){
//       setError("Enter Password")
//     }else{
   
//     console.log("email", email);
//     console.log("password", password);
//     const user = {
//       email: email,
//       password: password
//     };
//     try {
//       await dispatch(authLogin(user));
  
//     } catch (error) {
//       console.error("API Request Error:", error);
//       // Handle error, show error message, etc text.
//     }
//   }
//   };
//   useEffect(() => {
//     if (loginState.loginSuccess === true) {
//       window.location.reload();

//       navigate('/document');
//     } else if (loginState.loginError != null) {
//       // Extract the error message from the loginError object
//       //const errorMessage = loginState.loginError;
//       //setErr(true);
//       //setErrMessage(errorMessage);
//       //setIsLoading(false);
//     }
//   }, [loginState.loginSuccess, loginState.loginError, navigate]);


//   function emailChange(e) {
//     setEmail(e.target.value);
//     console.log(email);
//   }

//   function passwordchange(e) {
//     setPassword(e.target.value);
//     console.log(password);
//   }






//   return (
//     <div className='main'>
//     {/* <ErrorMessage message={a || ''}/> */}
//       <div className='sub-main'>
//         <div>
//           <div >
//             <div className='icon'>
//               <UserOutlined className='icon1'></UserOutlined>
//               <div>
//                 <h1>LOGIN </h1>
//               </div>
//               <div>

//                 <div id="loginForm">

//                   <MailOutlined className='email' />
//                   <input type="text" placeholder="Email" className="name" id="email" name="Email"
//                     autoComplete='off' onChange={emailChange} />
//                   <div />
//                   {error === "Enter Email" ? <p>{error}</p>:a === "Invalid credentials" ?<p style={{color:'red'}}>{a}</p>:null}
                    
//                   <div className='second-input'>
//                     <LockOutlined className='email' />
//                     <input type="password" placeholder="Password" className="name" id="password" name="password" autoComplete='off'
//                       onChange={passwordchange} />

//                   </div>
//                   {error === "Enter Password" ? <p>{error}</p>:a === "Invalid credentials" ?<p style={{color:'red'}}>{a}</p>:null}
//                 </div>
                
//                 <div className='login-button'>
//                   <button type='submit' className='submit' onClick={handleLoginClick}>Login</button>
                  
//                 </div>

//                 <p className='link'>
//                   <a href="#">Forget Password </a>or <a href="#">Sign Up </a>
//                 </p>



//               </div>

//               <div container="container-image">

//               </div>
//             </div>

//           </div>
//         </div>

//       </div>

//     </div>
//   );
// }
// export default Login;
