import { HomeOutlined,UpCircleOutlined,FileAddOutlined,BookOutlined,AppstoreAddOutlined,UserOutlined,UserAddOutlined,CalendarOutlined, EditOutlined, FormOutlined} from '@ant-design/icons';
import { MdOutlineAdminPanelSettings } from "react-icons/md";
const menuData = {
  id: 'group-dashboard',
  type: 'group',
  defaultOpenKey: 'dashboard', 
  children: [
    {
      id: 'document',
      title: 'Document',
      type: 'item',
      url: '/document',
      icon: <FileAddOutlined />,
      // breadcrumbs: false,
    },
    {
      id: 'module',
      title: 'Module',
      type: 'item',
      url: '/module',
      icon: <AppstoreAddOutlined />,
      // breadcrumbs: false,
    },
    {
      id: 'course',
      title: 'Course',
      type: 'item',
      url: '/course',
      icon: <BookOutlined />
      // breadcrumbs: false,
    },
    // {
    //   id: 'timeTable',
    //   title: 'Time Table',
    //   type: 'item',
    //   url: '/timeTable',
    //   icon: <CalendarOutlined />,
    //   // breadcrumbs: false,
    // },
    {
      id: 'accessControl',
      title: 'Access Control',
      type: 'item',
      // url: '/addCard',
      icon: <MdOutlineAdminPanelSettings size={22} style={{marginRight:"8px"}}/>,
      children: [
        {
          id: 'sub-user-1',
          title: 'Document Types',
          type: 'item',
          url: '/acessControll/documentTypes',
          icon: <FormOutlined />,
          // breadcrumbs: false,
        },
        {
          id: 'sub-user-2',
          title: 'Module Types',
          type: 'item',
          url: '/acessControll/moduleTypes',
          icon: <FormOutlined />,
          // breadcrumbs: false,
        },
      ]
    },
    // {
    //   id: 'addCard',
    //   title: 'AddCard',
    //   type: 'item',
    //   url: '/addCard',
    //   icon: <HomeOutlined />,
    //   // breadcrumbs: false,
    // },
    // {
    //   id: 'UserManagement',
    //   title: 'User Management',
    //   type: 'item',
    //   // url: '/addCard',
    //   icon: <UserOutlined />,
    //   children: [
    //     {
    //       id: 'sub-user-1',
    //       title: 'User',
    //       type: 'item',
    //       url: '/user',
    //       icon: <UserAddOutlined />,
    //       // breadcrumbs: false,
    //     },
    //     {
    //       id: 'sub-user-2',
    //       title: 'Role Create',
    //       type: 'item',
    //       url: '/roles',
    //       icon: <HomeOutlined />,
    //       // breadcrumbs: false,
    //     },
    //     {
    //       id: 'permission',
    //       title: 'Permission ',
    //       type: 'item',
    //       url: '/permission',
    //       icon: <UpCircleOutlined />,
    //       // breadcrumbs: false,
    //     },
    //   ]
    // }
   
  ],
};

export default menuData;