import React from 'react';
import NavItem from './menuitem';
import MenuHeader from './menuHeader';
import '../assets/Drawer.css';

function Drawer({ isOpen }) {
  return (
    <div  className={isOpen ? 'drawercar' : 'drawercarc'} style={{position:'fixed'}}>
       <MenuHeader />
      <div className={isOpen ? 'drawercard' : 'drawercardc'}>
          <NavItem opens={isOpen}/>
      </div>
      {/* {isOpen? */}
     
    {/* :
    null} */}
      
    </div>
  );
}

export default Drawer;