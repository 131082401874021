import React, { useState, useEffect } from 'react';
import '../../css/Errormsg.css';

function ErrorMessage({ message }) {
 console.log("messagemessagemessage",message);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  useEffect(() => {
    console.log(message,"error message");
    if (message !== '') {
        setShowErrorMessage(true);
      setTimeout(() => {
        setShowErrorMessage(false); 
      }, 3000); 
    }
  }, [message]);

  return (
    <div>
    
      <div className={`error-message-container ${showErrorMessage ? 'show-error-message' : ''}`}>
        {message}
      </div>
    </div>
  );
}

export default ErrorMessage;