import React from 'react';
import { Container, Row, Col, Button } from 'react-bootstrap';
import { useLocation, useNavigate } from 'react-router-dom';
import moment from 'moment';
import '../../assets/ModuleHistory.css';

const ViewModule = (props) => {
  const location = useLocation();
  const { data } = location.state || {};
  const navigate = useNavigate();
  
  const handleViewDocClick = (data) => {
    console.log("jjjnhgnghnhg");
    navigate(`/document`, { state: { docId:data} });
  };

  return (
    <div>
     
          <h3>
            <h2>{data?.name}</h2>
          </h3>
      <div className="history-container">
      <Container style={{maxHeight: 'calc(100vh - 200px)'}}>
        <Row >
          <Col md={6}>
            <p><span style={{fontWeight:'bold'}}>Description:</span> {data?.desc}</p>
            <p><span style={{fontWeight:'bold'}}>Status:</span> {data?.status}</p>
            <p><span style={{fontWeight:'bold'}}>Type:</span> {data?.type}</p>
            <p><span style={{fontWeight:'bold'}}>Duration:</span> {data?.duration}</p>
          </Col>
          <Col md={6}>
            <p><span style={{fontWeight:'bold'}}>Tab Text:</span> {data?.tabText}</p>
            <p><span style={{fontWeight:'bold'}}>Active:</span> {data?.active === true ? 'Active':'InActive'}</p>
            <p><span style={{fontWeight:'bold'}}>Notes:</span> {data?.notes}</p>
          </Col>
        </Row>
        <Row>
          <Col>
            <h4>Documents</h4>
              {data?.document?.map((doc, index) => (
                <div className="history-item" style={{ marginBottom: '10px' }} key={index}>
                  <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}> <p><span style={{ fontWeight: 'bold' }}>{index + 1}.File Name:</span> {doc?.fileName}</p>   <Button onClick={() => handleViewDocClick(doc?._id)}>view</Button></div>
                 
                  <p><span style={{ fontWeight: 'bold' }}>Created Date:</span> {moment(doc?.dateAndTime).format('MMMM Do YYYY, h:mm:ss a')}</p>
                </div>
              ))}
          
          </Col>
        </Row>
      </Container>
      </div>
    </div>
  );
};

export default ViewModule;
